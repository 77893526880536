import React from "react";

const Section2 = () => {
  return (
    <div className="container mx-auto flex flex-col justify-center items-center gap-16 py-20 border-b border-gray-800">
      <h2 className="text-5xl text-black font-bold">WHY YUN?</h2>
      <div className="grid grid-cols-5 gap-4">
        <div className="flex flex-col items-center gap-5">
          <img
            src="../assets/images/yunEffective.png"
            alt=""
            className="w-[100px]"
          />
          <h2 className="text-black text-2xl font-bold">EFFECTIVE</h2>
          <p className="text-black text-base font-light text-center">
            Effective alternative to existing medication and as safe as skin
            care
          </p>
        </div>
        <div className="flex flex-col items-center gap-5">
          <img
            src="../assets/images/yunSafe.png"
            alt=""
            className="w-[100px]"
          />
          <h2 className="text-black text-2xl font-bold">SAFE</h2>
          <p className="text-black text-base font-light text-center">
            Zero side effects even for the most sensitive skin types.
          </p>
        </div>
        <div className="flex flex-col items-center gap-5">
          <img
            src="../assets/images/yunFast.png"
            alt=""
            className="w-[100px]"
          />
          <h2 className="text-black text-2xl font-bold">FAST</h2>
          <p className="text-black text-base font-light text-center">
            A balanced microbiome and visible improvement in no time
          </p>
        </div>
        <div className="flex flex-col items-center gap-5">
          <img
            src="../assets/images/yunBacteria.png"
            alt=""
            className="w-[100px]"
          />
          <h2 className="text-black text-2xl font-bold">LIVE BACTERIA</h2>
          <p className="text-black text-base font-light text-center">
            World’s only skin care products with live bacteria, thanks to our
            micro-encapsulation technique
          </p>
        </div>
        <div className="flex flex-col items-center gap-5">
          <img
            src="../assets/images/yunMicrobiome.png"
            alt=""
            className="w-[100px]"
          />
          <h2 className="text-black text-2xl font-bold">MICROBIOME SAFE</h2>
          <p className="text-black text-base font-light text-center">
            Patented formulations with 100% microbiome safe ingredients
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section2;
