import React from "react";

const Section1 = () => {
  return (
    <div className="w-full flex border-b border-[#FDC899]">
      <div className="w-1/2">
        <img src="/assets/images/yunPro4.png" alt="" />
      </div>
      <div className="w-1/2 bg-[#FDC899] flex flex-col gap-16 justify-center pl-[100px]">
        <div className="flex flex-col gap-8">
          <h1 className="text-white text-5xl font-bold uppercase text-stroke-2 text-stroke-white">
            IS ECZEMA IMPACTING
            <br /> YOUR SYSTEM?
          </h1>
          <h2 className="text-black text-4xl font-bold uppercase">GO FOR PROBIOTICS!</h2>
        </div>
        <p className="text-black text-lg font-light pr-8">
          Don't give eczema a chance to upset your system. Discover our probiotic care
          products for an effective and lasting solution to atopic dermatitis.
        </p>
      </div>
    </div>
  );
};

export default Section1;
