import React from "react";

const Section1 = () => {
  return (
    <div className="flex h-[calc(100vh-92px)]">
      <div className="relative w-[55%] h-full">
        <img
          src="../assets/images/yun1.jpg"
          alt=""
          className="w-full h-full object-cover"
        />
        <img
          src="../assets/images/logoYun.png"
          alt=""
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </div>
      <div className="flex flex-col w-[45%]">
        <div className="relative bg-[#00ABAA] h-2/3">
          <div className="absolute top-[60%] left-[15%] -translate-y-1/2">
            <h2 className="text-black text-[40px] font-black uppercase">
              Love your <br /> bacteria.
            </h2>
            <span className="text-black text-[40px] leading-[20px] font-medium uppercase">
              WE DO.
            </span>
            <hr className="w-1/2 border-[#EAB1D5] border-[5px]" />
          </div>
        </div>
        <div className="relative bg-[#FAE0D0] h-1/3">
          <div className="absolute top-1/2 left-[15%] -translate-y-1/2 w-[70%]">
            <h2 className="text-black text-[30px] font-black uppercase">
              PROBIOTHERAPY
            </h2>
            <hr className="w-1/2 border-[#E76415] border-[5px]" />
            <span className="text-black text-lg">
              After 10 years of university research, the amazing power of
              millions of live bacteria is within your reach. Goodbye skin
              problems, hello healthy microbiome!
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
