import React from "react";

const Section2 = () => {
  return (
    <div className="container mx-auto flex flex-col justify-center items-center gap-24 py-16">
      <h2 className="text-5xl text-black font-bold">A WORLD OF DIFFERENCE</h2>
      <div className="grid grid-cols-5 gap-4">
        <div className="flex flex-col items-center gap-5">
          <img src="/assets/images/yunBacteria.png" alt="" className="w-[100px]" />
          <h2 className="text-black text-xl font-bold text-center text-stroke-[1px] text-stroke-black">LIVE LACTOBACILLUS </h2>
          <p className="text-black text-base font-light text-center">
            The world's only skincare products with live bacteria, thanks to our magical
            micro-encapsulation technique.
          </p>
        </div>
        <div className="flex flex-col items-center gap-5">
          <img src="/assets/images/yunFast.png" alt="" className="w-[100px]" />
          <h2 className="text-black text-xl font-bold text-center text-stroke-[1px] text-stroke-black">SUPER FAST</h2>
          <p className="text-black text-base font-light text-center">
            At top speed: 100% visible improvement after 4 weeks.*
          </p>
        </div>
        <div className="flex flex-col items-center gap-5">
          <img src="/assets/images/yunEffective.png" alt="" className="w-[100px]" />
          <h2 className="text-black text-xl font-bold text-center text-stroke-[1px] text-stroke-black">EFFECTIVE</h2>
          <p className="text-black text-base font-light text-center">
            Effective alternative to existing medication that is as safe as skin care.{" "}
          </p>
        </div>
        <div className="flex flex-col items-center gap-5">
          <img src="/assets/images/yunSafe.png" alt="" className="w-[100px]" />
          <h2 className="text-black text-xl font-bold text-center text-stroke-[1px] text-stroke-black">BYE BYE SIDE EFFECTS</h2>
          <p className="text-black text-base font-light text-center">
            Sensitive skin? No problem! ACN has zero side effects and you can use it
            indefinitely.
          </p>
        </div>
        <div className="flex flex-col items-center gap-5">
          <img src="/assets/images/yunMicrobiome.png" alt="" className="w-[100px]" />
          <h2 className="text-black text-xl font-bold text-center text-stroke-[1px] text-stroke-black">MICROBIOME SAFE</h2>
          <p className="text-black text-base font-light text-center">
            Patented formulations with 100% microbiome safe ingredients
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section2;
