import React from "react";

const Section5 = () => {
  return (
    <div className="bg-[#FEEFE1] py-10">
      <div className="container mx-auto flex flex-col gap-20 ">
        <div className="grid grid-cols-4 gap-10">
          <img
            src="/assets/images/yun64.png"
            alt=""
            className="shadow-[-10px_-10px_0_0_#EAB696]"
          />
          <img
            src="/assets/images/yun65.png"
            alt=""
            className="shadow-[-10px_-10px_0_0_#EAB696]"
          />
          <img
            src="/assets/images/yun66.png"
            alt=""
            className="shadow-[-10px_-10px_0_0_#EAB696]"
          />
          <img
            src="/assets/images/yun67.png"
            alt=""
            className="shadow-[-10px_-10px_0_0_#EAB696]"
          />
        </div>
        <div className="flex gap-10 w-[80%] mx-auto">
          <img src="/assets/images/yun68.png" alt="" className="h-[30px] object-cover" />
          <div className="flex flex-col gap-8">
            <h1 className="text-xl italic text-black text-center mx-auto">
              This cream is a miracle... I switched from using strong oral and topical
              cortisone products such as Elocom, Medrol, Prednisolone, etc. It got worse
              over the years. In just a week the YUN cream made me feel normal again,
              something I haven't experienced for over a year! Thank you!
            </h1>
            <h2 className="text-2xl italic text-black font-black w-[80%] text-center mx-auto">
              FELICIA G.
            </h2>
          </div>
          <img src="/assets/images/yun68.png" alt="" className="h-[30px] object-cover mt-[80px]" />
        </div>
      </div>
    </div>
  );
};

export default Section5;
