import React from "react";

const Section6 = () => {
  return (
    <div className="py-20 container mx-auto flex flex-col gap-10 justify-center items-center">
      <div className="flex flex-col gap-6 justify-center items-center">
        <h1 className="text-center text-4xl font-bold text-black uppercase text-stroke-1 text-stroke-black">
          How does the microbiome form in babies?
        </h1>
        <p className="text-xl text-center px-20 font-light">
          Building up the microbiome in babies is a critical period. This composition
          determines their lifelong health when they are child, adolescent, adult and even
          already in their elderly years!
        </p>
      </div>
      <div className="grid grid-cols-3 gap-6">
        <div className="relative bg-tableBlue w-full min-h-[360px] bg-[length:100%_100%] bg-no-repeat flex justify-center items-center">
          <div className="z-[-1] absolute top-1/2 -right-[25px] -translate-y-1/2 w-10 h-7 bg-[#8CD6EE]"></div>
          <div className="w-[85%] flex gap-4 justify-center items-center pt-10">
            <img
              src="/assets/images/yun53.png"
              alt=""
              className="h-[100px] object-cover"
            />
            <p className="text-left text-lg font-light">
              First contact with lactobacilli in mum's womb in amniotic fluid and via
              umbilical cord
            </p>
          </div>
        </div>
        <div className="relative bg-tableBlue w-full min-h-[360px] bg-[length:100%_100%] bg-no-repeat flex justify-center items-center">
          <div className="z-[-1] absolute top-1/2 -right-[25px] -translate-y-1/2 w-10 h-7 bg-[#8CD6EE]"></div>
          <div className="w-[85%] flex gap-4 justify-center items-center pt-10">
            <img
              src="/assets/images/yun54.png"
              alt=""
              className="h-[100px] object-cover"
            />
            <p className="text-left text-lg font-light">
              During natural birth, baby gets mum's microbiome through the birth canal as
              a bacterial starter pack
            </p>
          </div>
        </div>
        <div className="relative bg-tableRed w-full min-h-[360px] bg-[length:100%_100%] bg-no-repeat flex justify-center items-center">
          <div className="w-[85%] flex gap-4 justify-center items-center pt-10">
            <img
              src="/assets/images/yun55.png"
              alt=""
              className="h-[100px] object-cover"
            />
            <p className="text-left text-lg font-light">
              In case of caesarean section and/or antibiotic use during delivery, the
              baby's microbiome may change
            </p>
          </div>
        </div>
        <div className="relative bg-tableBlue w-full min-h-[360px] bg-[length:100%_100%] bg-no-repeat flex justify-center items-center">
          <div className="z-[-1] absolute top-1/2 -right-[25px] -translate-y-1/2 w-10 h-7 bg-[#8CD6EE]"></div>
          <div className="w-[85%] flex gap-4 justify-center items-center pt-10">
            <img
              src="/assets/images/yun56.png"
              alt=""
              className="h-[100px] object-cover"
            />
            <p className="text-left text-lg font-light">
              Environmental factors (family, pets, outdoor air, diet) strengthen the
              microbiome
            </p>
          </div>
        </div>
        <div className="relative bg-tableRed w-full min-h-[360px] bg-[length:100%_100%] bg-no-repeat flex justify-center items-center">
          <div className="z-[-1] absolute top-1/2 -right-[25px] -translate-y-1/2 w-8 h-7 bg-[#8CD6EE]"></div>
          <div className="w-[85%] flex gap-4 justify-center items-center pt-10">
            <img
              src="/assets/images/yun57.png"
              alt=""
              className="h-[100px] object-cover"
            />
            <p className="text-left text-lg font-light">
              Regular baby care can weaken the microbiome, protection with microbiome-safe
              products is essential
            </p>
          </div>
        </div>
        <div className="relative bg-tableBlue w-full min-h-[360px] bg-[length:100%_100%] bg-no-repeat flex justify-center items-center">
          <div className="z-[-1] absolute -top-4 left-1/2 -translate-y-1/2 -translate-x-1/2 w-7 h-10 bg-[#8CD6EE]"></div>
          <div className="w-[85%] flex gap-4 justify-center items-center pt-10">
            <img
              src="/assets/images/yun58.png"
              alt=""
              className="h-[100px] object-cover"
            />
            <p className="text-left text-lg font-light">
              Breastfeeding not only provides healthy vitamins but also a multitude of
              good bacteria, lactobacilli, that provide a healthy gut microbiome
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section6;
