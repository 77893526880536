import React from "react";
import { FormattedMessage } from "react-intl";

const Section13 = () => {
  return (
    <div className="p-6 xl:p-0 container mx-auto flex flex-col gap-10 justify-center items-center">
      <div className="flex flex-col gap-4 items-center">
        <h2 className="text-[32px] leading-[1.2em] uppercase font-semibold text-[#575756]">
          <FormattedMessage id="bio.section13.title" />
        </h2>
        <hr className="w-[30%] border-t border-4 border-[#3088B3]" />
      </div>
      <div className="grid grid-cols-1 xl:grid-cols-4 gap-4  xl:gap-2">
        <div className="flex flex-col border border-cyan-600 rounded-lg overflow-hidden">
          <img
            src="https://www.biodermogenesi.com/uk/wp-content/uploads/sites/4/2024/12/IMCAS_2025_generale-1080x675.png"
            alt="Biodermogenesi"
          />
          <div className="flex flex-col gap-2 p-4 bg-gray-200 h-full">
            <h3 className="text-base uppercase font-semibold text-cyan-700">
              <FormattedMessage id="bio.section13.post1.title" />
            </h3>
            <p className="text-sm text-gray-500">
              <FormattedMessage id="bio.section13.post1.description" />
            </p>
          </div>
          <div className="text-white w-full bg-cyan-600 px-3 py-2 text-center">
            <FormattedMessage id="bio.section13.post1.date" />
          </div>
        </div>
        <div className="flex flex-col border border-cyan-600 rounded-lg overflow-hidden">
          <img
            src="https://www.biodermogenesi.com/uk/wp-content/uploads/sites/4/2024/12/biodermo_facebody_pl_post-1080x675.png"
            alt="Biodermogenesi"
          />
          <div className="flex flex-col gap-2 p-4 bg-gray-200 h-full">
            <h3 className="text-base uppercase font-semibold text-cyan-700">
              <FormattedMessage id="bio.section13.post2.title" />
            </h3>
            <p className="text-sm text-gray-500">
              <FormattedMessage id="bio.section13.post2.description" />
            </p>
          </div>
          <div className="text-white w-full bg-cyan-600 px-3 py-2 text-center">
            <FormattedMessage id="bio.section13.post2.date" />
          </div>
        </div>
        <div className="flex flex-col border border-cyan-600 rounded-lg overflow-hidden">
          <img
            src="https://www.biodermogenesi.com/uk/wp-content/uploads/sites/4/2024/11/meme_webinar_uk_post-1080x675.png"
            alt="Biodermogenesi"
          />
          <div className="flex flex-col gap-2 p-4 bg-gray-200 h-full">
            <h3 className="text-base uppercase font-semibold text-cyan-700">
              <FormattedMessage id="bio.section13.post3.title" />
            </h3>
            <p className="text-sm text-gray-500">
              <FormattedMessage id="bio.section13.post3.description" />
            </p>
          </div>
          <div className="text-white w-full bg-cyan-600 px-3 py-2 text-center">
            <FormattedMessage id="bio.section13.post3.date" />
          </div>
        </div>
        <div className="flex flex-col border border-cyan-600 rounded-lg overflow-hidden">
          <img
            src="https://www.biodermogenesi.com/uk/wp-content/uploads/sites/4/2024/09/maurizio_cirm_28.09.24_EN-1080x675.png"
            alt="Biodermogenesi"
          />
          <div className="flex flex-col gap-2 p-4 bg-gray-200 h-full">
            <h3 className="text-base uppercase font-semibold text-cyan-700">
              <FormattedMessage id="bio.section13.post4.title" />
            </h3>
            <p className="text-sm text-gray-500">
              <FormattedMessage id="bio.section13.post4.description" />
            </p>
          </div>
          <div className="text-white w-full bg-cyan-600 px-3 py-2 text-center">
            <FormattedMessage id="bio.section13.post4.date" />
          </div>
        </div>
      </div>
      <button className="bg-[#3088B3] uppercase w-[180px] text-white px-4 py-2 rounded-md">
        <FormattedMessage id="readMore" />
      </button>
    </div>
  );
};

export default Section13;
