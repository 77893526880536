import React from "react";

const Section8 = () => {
  return (
    <div className="flex flex-col w-full">
      <div className="bg-[#00ACAB] w-full text-black text-3xl uppercase font-bold text-center py-20">
        Frequently Asked Questions ▼
      </div>
      <div className="bg-[#BEDFDE] w-full text-black text-3xl uppercase font-bold text-center py-20">
        How to use ▼
      </div>
    </div>
  );
};

export default Section8;
