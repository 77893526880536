import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useLanguage } from "../../contexts/LanguageContext";

const Header = () => {
  const { toggleLanguage, locale } = useLanguage();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const fontClass = locale === "vi" ? "font-helvetica" : "font-sathu"; // Xác định class cho font dựa trên ngôn ngữ

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLanguageChange = (lang) => {
    toggleLanguage(lang);
  };

  return (
    <div className={`sticky z-[100] top-0 left-0 w-full  bg-primary  ${fontClass}`}>
      <div
        className={`px-4 z-[100] top-0 left-0 w-full flex justify-between items-center bg-primary h-[92px] md:px-[40px] lg:px-[120px] ${fontClass}`}
      >
        <a href="/">
          <img src="/assets/logos/logo4.png" alt="logo" className="h-16 object-cover" />
        </a>

        {/* Menu cho màn hình lớn */}
        <div className="hidden md:flex items-center gap-[22px]">
          <a href="/about" className="text-white uppercase text-[18px] font-medium">
            <FormattedMessage id="header.about" />
          </a>
          <a href="/products" className="text-white uppercase text-[18px] font-medium">
            <FormattedMessage id="header.products" />
          </a>
          <a href="/contact" className="text-white uppercase text-[18px] font-medium">
            <FormattedMessage id="header.contact" />
          </a>
          <a href="/partner" className="text-white uppercase text-[18px] font-medium">
            <FormattedMessage id="header.partner" />
          </a>

          <div className="flex flex-row gap-3 items-center right-1">
            <button
              onClick={() => handleLanguageChange("vi")}
              className={`p-2 flex flex-row items-center justify-center text-sm font-medium rounded-full text-gray-700 hover:bg-gray-100 focus:outline-none w-[70px] ${
                locale === "vi" ? "bg-gray-200" : ""
              }`}
            >
              <span className="text-md">Vi</span>
              <span className="ml-1">
                <img src="/assets/logos/vietnam.png" className="w-6 h-6" alt="Vietnamese flag" />
              </span>
            </button>
            <button
              onClick={() => handleLanguageChange("en")}
              className={`p-2 flex flex-row items-center justify-center text-sm font-medium rounded-full text-gray-700 hover:bg-gray-100 focus:outline-none w-[70px] ${
                locale === "en" ? "bg-gray-200" : ""
              }`}
            >
              <span className="text-md">En</span>
              <span className="ml-1">
                <img
                  src="https://img.icons8.com/?size=512&id=t3NE3BsOAQwq&format=png"
                  className="w-6 h-6"
                  alt="English flag"
                />
              </span>
            </button>
          </div>
        </div>

        {/* Menu bar cho điện thoại */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-white">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>

        {/* Menu cho điện thoại */}
        {isMenuOpen && (
          <div
            ref={menuRef}
            className="absolute top-[92px] flex flex-col items-center py-2 border-y border-gray-300 left-0 w-full bg-primary md:hidden"
          >
            <a href="/about" className="block py-2 px-4 text-white">
              <FormattedMessage id="header.about" />
            </a>
            <a href="/products" className="block py-2 px-4 text-white">
              <FormattedMessage id="header.products" />
            </a>
            <a href="/contact" className="block py-2 px-4 text-white">
              <FormattedMessage id="header.contact" />
            </a>
            <a href="/partner" className="block py-2 px-4 text-white">
              <FormattedMessage id="header.partner" />
            </a>
            <div className="flex justify-center py-2 gap-4">
              <button
                onClick={() => handleLanguageChange("vi")}
                className={`p-2 flex flex-row items-center justify-center text-sm font-medium rounded-full text-gray-700 hover:bg-gray-100 focus:outline-none w-[70px] ${
                  locale === "vi" ? "bg-gray-200" : ""
                }`}
              >
                <span className="text-md">Vi</span>
                <span className="ml-1">
                  <img
                    src="/assets/logos/vietnam.png"
                    className="w-6 h-6"
                    alt="Vietnamese flag"
                  />
                </span>
              </button>
              <button
                onClick={() => handleLanguageChange("en")}
                className={`p-2 flex flex-row items-center justify-center text-sm font-medium rounded-full text-gray-700 hover:bg-gray-100 focus:outline-none w-[70px] ${
                  locale === "en" ? "bg-gray-200" : ""
                }`}
              >
                <span className="text-md">En</span>
                <span className="ml-1">
                  <img
                    src="https://img.icons8.com/?size=512&id=t3NE3BsOAQwq&format=png"
                    className="w-6 h-6"
                    alt="English flag"
                  />
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
