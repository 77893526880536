import React from 'react'

const Section11 = () => {
  return (
    <div className="flex flex-col gap-8 items-center justify-center bg-[#F6D5DA]">
      <h1 className="text-3xl uppercase text-black text-stroke-1 text-stroke-black font-black py-20">
        Frequently asked questions ▼
      </h1>
    </div>
  )
}

export default Section11
