import React from "react";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";
import Section8 from "./Section8";
import Section9 from "./Section9";

const Acn = () => {
  return (
    <div className="w-full">
      <Header />
      <div className="flex flex-col gap-8 xl:gap-16 w-full">
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <Section8 />
        <Section9 />
      </div>
      <Footer />
    </div>
  );
};

export default Acn;
