import React from "react";

const Section12 = () => {
  return (
    <div className="container mx-auto flex flex-col gap-8 xl:gap-16 w-full pb-20 border-b border-black">
      <h1 className="text-5xl uppercase font-bold text-center">
        THERE’S PLENTY MORE YUN!
      </h1>
      <div className="grid grid-cols-3 gap-6">
        <div className="flex flex-col gap-10 justify-center items-center">
          <img
            src="/assets/images/yun26.png"
            alt=""
            className="w-[75%] aspect-square object-cover"
          />
          <div className="flex flex-col justify-center items-center">
            <h2 className="text-2xl font-bold">BBY</h2>
            <p className="text-lg font-light">Baby skin care</p>
          </div>
          <button className="bg-black text-white px-5 py-2 rounded-md w-fit">
            Discover BBY
          </button>
        </div>
        <div className="flex flex-col gap-10 justify-center items-center">
          <img
            src="/assets/images/yun26.1.png"
            alt=""
            className="w-[75%] aspect-square object-cover"
          />
          <div className="flex flex-col justify-center items-center">
            <h2 className="text-2xl font-bold">ACN</h2>
            <p className="text-lg font-light">Acne symptoms</p>
          </div>
          <button className="bg-black text-white px-5 py-2 rounded-md w-fit">
            Discover ACN
          </button>
        </div>
        <div className="flex flex-col gap-10 justify-center items-center">
          <img
            src="/assets/images/yun28.png"
            alt=""
            className="w-[75%] aspect-square object-cover"
          />
          <div className="flex flex-col justify-center items-center">
            <h2 className="text-2xl font-bold">FNG</h2>
            <p className="text-lg font-light">Foot & skin fungus</p>
          </div>
          <button className="bg-black text-white px-5 py-2 rounded-md w-fit">
            Discover FNG
          </button>
        </div>
      </div>
    </div>
  );
};

export default Section12;
