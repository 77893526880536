import React from "react";

const Section9 = () => {
  return (
    <div className="flex justify-center items-center gap-20 container mx-auto">
      <div className="shadow-[-20px_20px_0_0_#EFB6C0] flex-shrink-0">
        <img src="/assets/images/yun37.png" alt="" className="object-cover" />
      </div>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col">
          <h1 className="text-4xl italic text-black text-stroke-2 text-stroke-black font-black pb-1 w-fit">
            GIVE YOUR INTIMATE BALANCE A CHANCE
          </h1>
          <hr className="w-[40%] border-[#EFB6C0] border-b-8" />
        </div>
        <p className="text-lg text-black font-light">
          Your vaginal microbiome is a unique ecosystem with millions of bacteria that
          play an essential role in your health. They protect you against infections, skin
          problems, bladder infections, etc. The antibacterial ingredients in classic
          vaginal care disrupt this balance and can lead to a lot of intimate discomfort.
        </p>
        <p className="text-lg text-black font-light">
          After years of university research to break this vicious circle in a natural
          way, VGN saw the light of day. VGN is exceptionally effective and can also be
          used on a daily basis, without any side effects. A pink middle ground!
        </p>
      </div>
    </div>
  );
};

export default Section9;
