import React from "react";

const Section7 = () => {
  return (
    <div className="w-full relative overflow-hidden flex flex-col">
      <div className="absolute z-[-1] bg-[#e4f6fe] w-2/5 h-[450px] right-0"></div>
      <div className="absolute top-[150px] z-[-1] bg-[#8BD5ED] w-4/5 h-[650px]"></div>
      <div className="mt-[150px] px-4 py-10 container mx-auto flex justify-center items-center gap-24 mb-40">
        <div className="flex flex-col gap-8">
          <div className="">
            <h1 className="text-5xl leading-[65px] italic text-black font-black w-full text-stroke-2 text-stroke-black">
              PROTECTED BY MILLIONS<br/> OF BACTERIA
            </h1>
            <hr className="w-1/3 border-4 border-[#DB7385]" />
          </div>
          <p className="text-lg text-black font-light">
            Cute and microscopic, at the same time lactobacilli are also super strong! The
            live bacteria in BBY PROBIOTIC Diaper Ointment form a sturdy shield and help
            your baby's microbiome recover in 1-2-3. They may be invisible to the naked
            eye, but you can clearly see the results of their work!
          </p>
          <button className="w-fit bg-black text-white px-8 py-5 text-base font-black uppercase">
            Discover more
          </button>
        </div>
        <div className="w-[100%]">
          <img src="/assets/images/yun59.png" alt="" className="object-contain" />
        </div>
      </div>
      <div className="h-[480px] ">
        <img 
          src="/assets/images/yun60.png" 
          alt="" 
          className="object-cover w-full h-[480px] object-bottom" 
        />
      </div>
    </div>
  );
};

export default Section7;
