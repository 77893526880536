import React from "react";
import { FormattedMessage } from "react-intl";

const Section5 = () => {
  return (
    <div className="bg-[radial-gradient(circle_at_right,rgba(239,216,214,0)_0%,#EFD8D6_100%)]">
      <div className="p-6 xl:p-0 xl:h-[450px] w-full container mx-auto relative flex justify-center items-center gap-16 overflow-hidden">
        <div className="hidden xl:block">
          <img
            src="../assets/images/bio2.png"
            alt="Biodermogenesi"
            className="w-[580px] h-full object-cover "
          />
        </div>
        <div className="flex flex-col gap-8">
          <h1 className="text-4xl xl:text-[56px] leading-[1.2em] uppercase font-semibold text-[#575756] xl:w-[560px]">
            <FormattedMessage id="bio.section5.title" />
          </h1>
          <h2 className="text-lg xl:text-[24px] leading-[1.2em] uppercase font-light text-[#575756] xl:w-[560px]">
            <FormattedMessage id="bio.section5.description" />
            <br />
            <strong className="font-bold">
              <FormattedMessage id="bio.section5.description.strong" />
            </strong>
          </h2>
          <div className="xl:hidden">
          <img
            src="../assets/images/bio2.png"
            alt="Biodermogenesi"
            className="w-[580px] h-full object-cover "
          />
        </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
