import React from "react";

const Section1 = () => {
  return (
    <div className="flex relative">
      <div className="relative flex-1 bg-[#8BD5ED] flex flex-col gap-4 justify-center pl-32">
        <h1 className="text-5xl font-bold text-black text-stroke-[2.5px] text-stroke-black uppercase">
          JUST LIKE IN <br />
          MUMMY's TUMMY
        </h1>
        <hr className="w-1/2 h-[10px] bg-[#f1d65d]" />
        <h2 className="text-3xl font-bold text-black uppercase">
          Baby’s best bacteria buddy’s for life
        </h2>
        <img src="/assets/images/yun40.png" alt="bby" className="absolute bottom-32 right-0 left-0" />
      </div>
      <div className="flex-1">
        <img
          src="/assets/images/yun39.png"
          alt="bby"
          className="h-[calc(100vh-92px)] w-full object-cover"
        />
      </div>
      <img src="/assets/images/yun41.png" alt="" className="absolute -bottom-12 object-cover left-0 w-full" />
      
    </div>
  );
};

export default Section1;
