import React from "react";

const Section10 = () => {
  return (
    <div className="container mx-auto flex justify-center items-center">
      <div className="flex gap-20 justify-center items-center w-[80%]">
        <img src="/assets/images/yun72.png" alt="" className="w-[570px] object-cover flex-shrink-0"/>
        <div className="flex flex-col gap-10">
          <h2 className="uppercase text-5xl font-bold italic text-stroke-2 text-stroke-black text-black">
            What can cause a flare-up of eczema?
          </h2>
          <p className="text-lg font-light">
            The lack of sufficient moisture in the skin can lead to a weakened barrier
            function and cause symptoms of eczema. Exposure to irritants such as soaps,
            detergents, cleaning products, perfumes and some cosmetics can aggravate
            eczema. You can reduce eczema flare-ups by following some simple rules and
            adjusting your washing and self-care habits.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section10;
