import React from "react";

const Section4 = () => {
  return (
    <div>
      <div className="flex flex-col justify-center items-center gap-16 py-20">
        <h2 className="text-5xl text-black font-bold">THE POWER OF PROBIOTHERAPY</h2>
        <div className="flex justify-center items-center gap-10">
          <div className="relative">
            <div className="relative z-[1]">
              <div className="shadow-[-40px_40px_0_0_#00ABAA]">
                <img src="../assets/images/yun2.png" alt="" className="object-cover" />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <h1 className="text-4xl italic text-black font-black pb-1 border-b-8 border-[#00ABAA] w-fit">
              THE BEST
              <br />
              OF BOTH WORLDS
            </h1>
            <p className="text-lg text-black font-light">
              The golden mean. The perfect solution. Or in short: probiotherapy!
            </p>
            <p className="text-lg text-black font-light">
              YUN solves your skin problems with the speed and efficiency of medicine, while being
              <br /> just as safe as regular skin care.
            </p>
            <p className="text-lg text-black font-light">
              How? Thanks to a daily dose of living bacteria! They preserve the natural balance of{" "}
              <br /> the skin and strengthen the good bacteria present on your skin.
            </p>
            <button className="w-fit bg-black text-white px-8 py-5 text-base font-black uppercase">
              Discover more
            </button>
          </div>
        </div>

        <div className="w-full relative overflow-hidden">
          <div className="absolute z-[-1] bg-[#F6E0E4] w-2/5 h-[450px] right-0"></div>
          <div className="absolute top-[150px] z-[-1] bg-[#ECB4BE] w-4/5 h-full"></div>
          <div className="mt-[150px] px-4 py-10 container mx-auto flex justify-center items-center gap-20">
            <div className="flex flex-col gap-8">
              <div>
                <h1 className="text-4xl italic text-black font-black w-full">
                  BACTERIA TAKE GOOD CARE OF US, BUT DO WE TAKE CARE OF THEM?
                </h1>
                <hr className="w-1/2 border-4 border-white" />
              </div>
              <p className="text-lg text-black font-light">
                The microbiome is your protective skin layer with both good and bad bacteria. Only
                when your microbiome is in balance does it offer strong protection against
                irritations and skin diseases.
              </p>
              <p className="text-lg text-black font-light">
                Our modern lifestyle damages the microbiome by overusing classical skin care
                products with antibiotics, antibacterial and aggressive ingredients. They destroy
                your good bacteria making us more susceptible to all kinds of bad bacteria and
                fungi.
              </p>
              <p className="text-lg text-black font-light">
                A revolutionary 180° view breaks this vicious circle: with millions of good bacteria
                and microbiome-safe formulations, we bring your skin back into a healthy balance.
              </p>
              <button className="w-fit bg-black text-white px-8 py-5 text-base font-black uppercase">
                Discover more
              </button>
            </div>
            <div className="w-[100%]">
              <img src="../assets/images/yun3.png" alt="" className="object-contain" />
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center gap-16 container mx-auto">
          <div className="relative z-[1]">
            <div className="shadow-[-40px_40px_0_0_#E76415]">
              <img src="../assets/images/yun4.png" alt="" className="object-cover" />
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <div>
              <h1 className="text-4xl italic text-black font-black pb-1 border-b-8 border-[#E76415] w-fit">
                CUTTING-EDGE
              </h1>
              <h1 className="text-4xl italic text-black font-black pt-1">
                SKINCARE
              </h1>
            </div>
            <p className="text-lg text-black font-light">
              After more than 10 years of research with the University of Antwerp, YUN made it
              possible to add live bacteria to skincare products. The battle against antibiotic
              resistance has begun!
            </p>
            <p className="text-lg text-black font-light">
              In collaboration with BBC Storyworks, we show an exclusive behind-the-scenes look at
              the wonderful world of YUN.
            </p>
            <button className="w-fit bg-black text-white px-8 py-5 text-base font-black uppercase">
              Discover more
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
