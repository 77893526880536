import React from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import Section1 from "./Section1";
import Section10 from "./Section10";
import Section11 from "./Section11";
import Section12 from "./Section12";
import Section13 from "./Section13";
import Section14 from "./Section14";
import Section15 from "./Section15";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";
import Section8 from "./Section8";
import Section9 from "./Section9";
import { useLanguage } from "../../../contexts/LanguageContext";

const Biodermogenesi = () => {
  const { locale } = useLanguage();
  const fontClass = locale === "vi" ? "font-helvetica" : "font-sathu";

  return (
    <div className={`w-full ${fontClass}`}>
      <Header />
      <div className="flex flex-col gap-8 xl:gap-16 w-full">
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <Section8 />
        <Section9 />
        <Section10 />
        <Section11 />
        <Section12 />
        <Section13 />
        <Section14 />
        <Section15 />
      </div>
      <Footer />
    </div>
  );
};

export default Biodermogenesi;
