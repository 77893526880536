import React from "react";

const Section2 = () => {
  return (
    <div className="container mx-auto flex flex-col justify-center items-center gap-16 py-20 border-b border-gray-800">
      <div className="grid grid-cols-5 gap-4">
        <div className="flex flex-col items-center gap-5">
          <img src="/assets/images/yun29.png" alt="" className="w-[100px]" />
          <h2 className="text-black text-xl font-bold text-center text-stroke-[1px] text-stroke-black">LIVE LACTOBACILLUS</h2>
          <p className="text-black text-base font-light text-center">
            The world's only skin care crème with live bacteria{" "}
          </p>
        </div>
        <div className="flex flex-col items-center gap-5">
          <img src="/assets/images/yunFast.png" alt="" className="w-[100px]" />
          <h2 className="text-black text-xl font-bold text-center text-stroke-[1px] text-stroke-black">FAST</h2>
          <p className="text-black text-base font-light text-center">
            A balanced microbiome and visible improvement in no time{" "}
          </p>
        </div>
        <div className="flex flex-col items-center gap-5">
          <img src="/assets/images/yunEffective.png" alt="" className="w-[100px]" />
          <h2 className="text-black text-xl font-bold text-center text-stroke-[1px] text-stroke-black">EFFECTIVE</h2>
          <p className="text-black text-base font-light text-center">
            Effective alternative to existing medication that is as safe as skin care{" "}
          </p>
        </div>
        <div className="flex flex-col items-center gap-5">
          <img src="/assets/images/yunSafe.png" alt="" className="w-[100px]" />
          <h2 className="text-black text-xl font-bold text-center text-stroke-[1px] text-stroke-black">
            NO SIDE EFFECTS
          </h2>
          <p className="text-black text-base font-light text-center">
            Sensitive skin? No problem! ATO has zero side effects and you can use it
            indefinitely
          </p>
        </div>
        <div className="flex flex-col items-center gap-5">
          <img src="/assets/images/yunMicrobiome.png" alt="" className="w-[100px]" />
          <h2 className="text-black text-xl font-bold text-center text-stroke-[1px] text-stroke-black">
            MICROBIOME SAFE
          </h2>
          <p className="text-black text-base font-light text-center">
            Patented formulas with 100% microbiome safe ingredients.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section2;
