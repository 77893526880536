import React from "react";

const Section3 = () => {
  return (
    <div className="container mx-auto flex justify-center items-center gap-20">
      <div className="flex-1">
        <img src="/assets/images/yun31.jpg" alt="" />
      </div>
      <div className="flex-1 flex flex-col gap-8">
        <div className="flex flex-col">
          <h1 className="text-4xl italic text-black font-black pb-1 text-stroke-2 text-stroke-black">
            WATER VS INTIMATE SOAP? WE
            <br />
            DID THE TEST...
          </h1>
          <hr className="w-[20%] border-[#eab696] border-b-8" />
        </div>
        <p className="text-lg text-black font-light">
          A healthy intimate microbiome is very important: it cleans the vagina, keeps the
          pH balance healthy and protects you from bad bacteria. So you avoid a lot of
          vaginal ailments like odor, infections, itching and irritation.
        </p>
        <p className="text-lg text-black font-light">
          But how can you get a clean and fresh intimate feeling without damaging its
          protective bacteria? Because well ... after all, does soap contain{" "}
          <strong className="font-bold">anti-bacterial ingredients</strong> and{" "}
          <strong className="font-bold">preservatives?</strong> In short, is there such a
          thing as microbiome-safe intimate soaps that are as safe as water?
        </p>
        <p className="text-lg text-black font-light">
          To investigate this, we did a{" "}
          <strong className="font-bold">microbiological analysis*</strong> in our lab. We
          compared the most common intimate soaps on the market with{" "}
          <strong className="font-bold">water</strong> and our{" "}
          <strong className="font-bold">YUN VGN washing gels.</strong>
        </p>
        <p className="text-sm text-black font-light">
          *To see the impact of these soaps on our vaginal microbiome, we simulated the
          average shower under controlled and standardized conditions. By putting selected
          bacterial strains (in this case vaginal Lactobacilli) in contact with a diluted
          soap, we were able to measure the effect on the growth capacity of those
          bacteria.{" "}
        </p>
      </div>
    </div>
  );
};

export default Section3;
