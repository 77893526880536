import React from "react";

const Section5 = () => {
  return (
    <div className="w-full relative overflow-hidden">
      <div className="absolute z-[-1] bg-[#F6E0E4] w-2/5 h-[450px] right-0"></div>
      <div className="absolute top-10 z-[-1] bg-[#ECB4BE] w-4/5 h-full"></div>
      <div className="mt-20 px-4 py-10 container mx-auto flex justify-center items-center gap-20">
        <div className="flex flex-col gap-8">
          <h1 className="text-5xl italic text-black font-black w-full text-stroke-2 text-stroke-black">
            CONCLUSION
          </h1>
          <p className="text-lg text-black font-bold">
            Can you refresh your intimate zone and boost your pH balance without damaging
            protective bacteria?
          </p>
          <p className="text-lg text-black font-light">
            <strong className="font-bold">Yes,</strong> thanks to YUN's intimate care that
            does work in harmony with the intimate microbiome.
          </p>
          <p className="text-lg text-black font-light">
            Together with the University of Antwerp, we carefully selected all
            ingredients, from surfactants to preservatives in function of the vaginal
            microbiome.
          </p>
          <p className="text-lg text-black font-light">
            <strong className="font-bold">Even better:</strong> with the VGN PROBIOTIC
            REPAIR Gel, you can now even add millions of those same protective bacteria
            that strengthen and restore the vaginal flora, helping to prevent irritation.
            Furthermore, you prevent the growth of harmful bacteria by pH-reducing lactic
            acid in the VGN wash gels. This way, you immediately build the optimal
            environment for good bacteria.
          </p>
        </div>
        <div className="flex-shrink-0">
          <img
            src="/assets/images/yunPro2.png"
            alt=""
            className="object-contain w-[650px] flex-shrink-0"
          />
        </div>
      </div>
    </div>
  );
};

export default Section5;
