import React from "react";
import { FormattedMessage } from "react-intl";

const Section11 = () => {
  return (
    <div className="p-6 xl:p-0 container mx-auto flex flex-col gap-10 justify-center items-center">
      <div className="flex flex-col gap-4 items-center">
        <h2 className="text-[32px] leading-[1.2em] text-center xl:text-left uppercase font-semibold text-[#575756]">
          <FormattedMessage id="bio.section11.title" />
        </h2>
        <hr className="w-[30%] border-t border-4 border-[#3088B3]" />
      </div>
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-10">
        <img
          src="https://www.biodermogenesi.com/uk/wp-content/uploads/sites/4/2022/12/Post-FB-medici-garcia02-en.jpg"
          alt="Biodermogenesi"
          className="border border-cyan-600 rounded-xl"
        />
        <img
          src="https://www.biodermogenesi.com/uk/wp-content/uploads/sites/4/2022/12/Post-FB-medici-beatini-en.jpg"
          alt="Biodermogenesi"
          className="border border-cyan-600 rounded-xl"
        />
        <img
          src="https://www.biodermogenesi.com/uk/wp-content/uploads/sites/4/2022/12/Post-FB-medici-lomuto-en.jpg"
          alt="Biodermogenesi"
          className="border border-cyan-600 rounded-xl"
        />
      </div>
      <button className="bg-[#3088B3] uppercase w-[180px] text-white px-4 py-2 rounded-md">
        Read more
      </button>
    </div>
  );
};

export default Section11;
