import React from "react";
import { FormattedMessage } from "react-intl";

const Section7 = () => {
  return (
    <div className="container mx-auto py-10 mt-10 flex flex-col gap-20 items-center justify-center">
      <h1 className="text-5xl font-bold uppercase">WANT MORE?</h1>
      <div className="flex justify-center items-center gap-10">
        <div className="shadow-[-40px_40px_0_0_#ea6515] flex-shrink-0">
          <img src="/assets/images/yun21.png" alt="" className="object-cover w-[540px]" />
        </div>
        <div className="flex flex-col gap-8">
          <h1 className="text-4xl italic text-black font-black pb-1 border-b-8 border-[#ea6515] w-fit">
            WHERE DOES MY ACNE COME FROM?
          </h1>
          <p className="text-lg text-black font-light">
            Acne and pimples have many underlying causes: hormonal, physical, nutritional,
            stress, medication, etc. Your skin starts to produce too much sebum and this
            forms a breeding ground for bad bacteria...
          </p>
          <button className="bg-black text-white px-5 py-2 rounded-md w-fit">
            <FormattedMessage id="readMore" />
          </button>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-6">
        <div className="flex flex-col gap-2 border border-gray-200 rounded-md shadow-md overflow-hidden">
          <img src="/assets/images/yun22.png" alt="" className="w-full aspect-square object-cover"/>
          <h2 className="text-lg font-bold p-4">REASONS WHY ADULTS CAN HAVE ACNE TOO.</h2>
        </div>
        <div className="flex flex-col gap-2 border border-gray-200 rounded-md shadow-md overflow-hidden">
          <img src="/assets/images/yun23.jpg" alt="" className="w-full aspect-square object-cover"/>
          <h2 className="text-lg font-bold p-4">TOPICAL CREAM WITH LIVE LACTOBACILLI MODULATES THE SKIN MICROBIOME AND REDUCE ACNE SYMPTOMS</h2>
        </div>
        <div className="flex flex-col gap-2 border border-gray-200 rounded-md shadow-md overflow-hidden">
          <img src="/assets/images/yun24.jpg" alt="" className="w-full aspect-square object-cover"/>
          <h2 className="text-lg font-bold p-4">SKIN PURGING OR JUST ACNE? 4 WAYS TO TELL THE DIFFERENCE</h2>
        </div>
        <div className="flex flex-col gap-2 border border-gray-200 rounded-md shadow-md overflow-hidden">
          <img src="/assets/images/yun25.png" alt="" className="w-full aspect-square object-cover"/>
          <h2 className="text-lg font-bold p-4">HOW DOES ACNE HAPPEN AND WHAT CAN YOU DO ABOUT IT?</h2>
        </div>
      </div>
    </div>
  );
};

export default Section7;
