import React from "react";

const Section4 = () => {
  return (
    <div className="container mx-auto flex flex-col gap-16">
      <div className="flex justify-center items-center gap-20">
        <div className="flex-1 flex flex-col gap-8">
          <div className="flex flex-col">
            <h1 className="text-4xl italic text-black font-black pb-1 text-stroke-2 text-stroke-black">
              THE RESULT
            </h1>
            <hr className="w-[20%] border-[#eab696] border-b-8" />
          </div>
          <p className="text-lg text-black font-light">
            We are proud to say that YUN VGN Hydra{" "}
            <strong className="font-bold">scores as well as water!</strong>
          </p>
          <p className="text-lg text-black font-light">
            Even if you only use water to wash, all the important bacteria are affected,
            and the vaginal microbiome always needs time to recover. Preferably as soon as
            possible to avoid an overgrowth of bad bacteria (and thus vaginal ailments).
          </p>
          <p className="text-lg text-black font-light">
            With classic soaps, it was noticeable that some bacteria had{" "}
            <strong className="font-bold">completely died off after immediate use</strong>{" "}
            and it even took up to <strong className="font-bold">more than 2 days</strong>{" "}
            (!) until these important vaginal lactobacilli were present again.
          </p>
          <p className="text-sm text-black font-light">
            YUN's intimate care does work in harmony with the intimate microbiome and is
            the only one as safe as water! (See graph)
          </p>
        </div>
        <div className="flex-1">
          <img src="/assets/images/yun32.png" alt="" />
        </div>
      </div>
      <div className="flex gap-4 justify-center items-center w-full">
        <div>
          <img src="/assets/images/yun6.png" alt="" />
        </div>
        <div>
          <img src="/assets/images/yun7.png" alt="" />
        </div>
        <div>
          <img src="/assets/images/yun8.png" alt="" />
        </div>
        <div>
          <img src="/assets/images/yun9.png" alt="" />
        </div>
        <div>
          <img src="/assets/images/yun10.png" alt="" />
        </div>
        <div>
          <img src="/assets/images/yun11.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section4;
