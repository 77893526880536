import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { LanguageProvider } from "./contexts/LanguageContext";
import AboutPage from "./pages/About";
import Contact from "./pages/Contact";
import HomePage from "./pages/Home";
import Partner from "./pages/Partner";
import Privacy from "./pages/Privacy";
import Products from "./pages/Products";
import Biodermogenesi from "./pages/Products/Biodermogenesi";
import Yun from "./pages/Products/Yun";
import Acn from "./pages/Products/Yun/Acn";
import Vgn from "./pages/Products/Yun/Vgn";
import Bby from "./pages/Products/Yun/Bby";
import Ato from "./pages/Products/Yun/Ato";
import Terms from "./pages/Terms";

export default function App() {
  return (
    <LanguageProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/biodermogenesi" element={<Biodermogenesi />} />
          <Route path="/products/yun" element={<Yun />} />
          <Route path="/products/yun/acn" element={<Acn />} />
          <Route path="/products/yun/vgn" element={<Vgn />} />
          <Route path="/products/yun/bby" element={<Bby />} />
          <Route path="/products/yun/ato" element={<Ato />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/partner" element={<Partner />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </BrowserRouter>
    </LanguageProvider>
  );
}
