import React from "react";

const Section3 = () => {
  return (
    <div className="bg-[#daf1f1] flex flex-col justify-center items-center gap-24 py-12">
      <h1 className="text-5xl text-black font-bold">WHICH PRODUCTS SHOULD I USE?</h1>
      <div className="bg-white p-10 flex flex-col gap-20 rounded-lg container mx-auto">
        <div className="grid grid-cols-2 gap-8">
          <div className="flex flex-col items-center gap-5">
            <h2 className="text-black text-2xl font-bold text-center">
              You have acne-prone skin and...
            </h2>
            <select className="w-full p-4 border bg-[#daf1f1] border-[#00a5a0] rounded-md">
              <option value="1">Option 1</option>
              <option value="2">Option 2</option>
              <option value="3">Option 3</option>
            </select>
          </div>
          <div className="flex flex-col items-center gap-5">
            <h2 className="text-black text-2xl font-bold text-center">
              I have more of a...
            </h2>
            <select className="w-full p-4 border bg-[#daf1f1] border-[#00a5a0] rounded-md">
              <option value="1">Option 1</option>
              <option value="2">Option 2</option>
              <option value="3">Option 3</option>
            </select>
          </div>
        </div>
        <h1 className="text-black text-4xl font-bold text-center">
          THIS IS YOUR IDEAL ROUTINE.
        </h1>
        <div className="flex flex-col items-center gap-8">
          <div className="grid grid-cols-3 gap-8">
            <div className="grid grid-rows-[auto_1fr_auto] items-center gap-5">
              <h2 className="text-[#00ACAB] text-3xl font-bold text-center">Step 1</h2>
              <p className="text-black text-xl font-bold text-center">
                ACN PREBIOTIC MICELLAR CLEANSING Water
              </p>
              <img src="/assets/images/yunStep1.jpg" alt="" />
            </div>
            <div className="grid grid-rows-[auto_1fr_auto] items-center gap-5">
              <h2 className="text-[#00ACAB] text-3xl font-bold text-center">Step 2</h2>
              <p className="text-black text-xl font-bold text-center">
                ACN GENTLE EXFOLIATING Wash
              </p>
              <img src="/assets/images/yunStep2.jpg" alt="" />
            </div>
            <div className="grid grid-rows-[auto_1fr_auto] items-center gap-5">
              <h2 className="text-[#00ACAB] text-3xl font-bold text-center">Step 3</h2>
              <p className="text-black text-xl font-bold text-center">
                ACN PROBIOTIC REPAIR Cream
              </p>
              <img src="/assets/images/yunStep3.jpg" alt="" />
            </div>
          </div>
          <h2 className="text-black text-2xl font-bold text-center">
            Handpicked just for you
          </h2>
          <p className="text-black text-xl font-bold text-center">
            + complimentary shipping (in the EU), because you asked so nicely 😉
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section3;
