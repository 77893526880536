import React from "react";

const Section8 = () => {
  return (
    <div className="container mx-auto flex flex-col gap-16">
      <div className="flex gap-24 justify-center items-center">
        <div className="flex flex-col gap-8 pr-20">
          <h1 className="text-5xl font-bold italic text-stroke-2 text-stroke-black text-black">
            ATO PROBIOTIC REPAIR CREAM
          </h1>
          <p className="text-black text-lg font-light">
            YUN ATO PROBIOTIC REPAIR Cream contains a unique patented encapsulation
            technique that keeps bacteria alive and active. Since many skin problems begin
            with an imbalance of bacteria on the skin, this cream is perfect for those
            with sensitive skin. It addresses the problem at its source, helping to break
            the cycle of recurring symptoms.
          </p>
          <p className="text-black text-lg font-light">
            The live lactobacilli in the cream suppress the eczema-causing Staphylococcus
            aureus bacteria, helping to reduce itching, redness, irritation and dryness.
          </p>
          <div className="flex justify-evenly gap-20 pt-10">
            <div className="flex flex-col gap-4 justify-center items-center">
              <img
                src="/assets/images/yun29.png"
                alt=""
                className="w-[100px] object-cover"
              />
              <h2>LIVE LACTOBACILLUS</h2>
            </div>
            <div className="flex flex-col gap-4 justify-center items-center">
              <img
                src="/assets/images/yunFast.png"
                alt=""
                className="w-[100px] object-cover"
              />
              <h2>FAST</h2>
            </div>
            <div className="flex flex-col gap-4 justify-center items-center">
              <img
                src="/assets/images/yunEffective.png"
                alt=""
                className="w-[100px] object-cover"
              />
              <h2>EFFECTIVE</h2>
            </div>
          </div>
        </div>
        <img src="/assets/images/yun71.png" alt="" className="h-[573px] object-cover" />
      </div>
      <div className="flex justify-evenly">
        <div className="flex flex-col gap-4 justify-center items-center">
          <h2 className="text-5xl font-bold italic text-stroke-2 text-stroke-black text-black border-b-[8px] border-[#EAB696]">
            96%
          </h2>
          <p className="text-black text-lg font-light">Skin feels hydrated and soft</p>
        </div>
        <div className="flex flex-col gap-4 justify-center items-center">
          <h2 className="text-5xl font-bold italic text-stroke-2 text-stroke-black text-black border-b-[8px] border-[#EAB696]">
            90%
          </h2>
          <p className="text-black text-lg font-light">less bad bacteria on the skin</p>
        </div>
        <div className="flex flex-col gap-4 justify-center items-center">
          <h2 className="text-5xl font-bold italic text-stroke-2 text-stroke-black text-black border-b-[8px] border-[#EAB696]">
            83%
          </h2>
          <p className="text-black text-lg font-light">less flaking</p>
        </div>
        <div className="flex flex-col gap-4 justify-center items-center">
          <h2 className="text-5xl font-bold italic text-stroke-2 text-stroke-black text-black border-b-[8px] border-[#EAB696]">
            95%
          </h2>
          <p className="text-black text-lg font-light">less irritation and tightness</p>
        </div>
        <div className="flex flex-col gap-4 justify-center items-center">
          <h2 className="text-5xl font-bold italic text-stroke-2 text-stroke-black text-black border-b-[8px] border-[#EAB696]">
            84%
          </h2>
          <p className="text-black text-lg font-light">less itching</p>
        </div>
      </div>
      <p className="text-black text-xs font-light text-center">
        * In vivo efficacy study – 40 people – atopic prone skin
      </p>
    </div>
  );
};

export default Section8;
