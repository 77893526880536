import React from "react";

const Section3 = () => {
  return (
    <div className="w-full relative overflow-hidden flex flex-col">
      <div className="absolute z-[-1] bg-[#FEE3CE] w-2/5 h-[200px] right-0 top-1/2"></div>
      <div className="absolute top-[150px] z-[-1] bg-[#FEE3CE] w-4/5 h-[650px]"></div>
      <div className="mt-[150px] px-4 py-10 container mx-auto flex justify-center items-center gap-24 mb-40">
        <div className="flex flex-col gap-8">
          <div className="">
            <h1 className="text-5xl leading-[65px] italic text-black font-black w-full text-stroke-2 text-stroke-black">
              THE PROBIOTIC SOLUTION FOR ECZEMA
            </h1>
          </div>
          <p className="text-lg text-black font-light">
            Atopic eczema, also called atopic dermatitis, is a chronic inflammatory skin
            disease that causes dryness, itching, redness, irritation and scaling.
          </p>
          <p className="text-lg text-black font-light">
            Atopic eczema occurs because there are too many bad bacteria (mostly
            Staphylococcus aureus) and not enough good bacteria on the skin. As a result,
            the skin's protective barrier, the microbiome, is disrupted. This allows
            allergens to get in and cause inflammation.{" "}
          </p>
          <p className="text-lg text-black font-light">
            YUN has developed a range of products that protect the microbiome. We also
            offer products that actively restore the skin barrier thanks to our unique
            formulation containing live lactobacilli, which inhibit the growth of
            Staphylococcus aureus and help to reduce symptoms.{" "}
          </p>
        </div>
        <div className="w-[100%]">
          <img src="/assets/images/yunPro4.png" alt="" className="object-contain" />
        </div>
      </div>
    </div>
  );
};

export default Section3;
