import React from "react";

const Section1 = () => {
  return (
    <div className="w-full flex">
      <div className="flex-1 bg-[#00ACAB] flex flex-col gap-16 justify-center pl-[125px]">
        <div className="flex flex-col gap-2">
          <h1 className="text-black text-5xl font-bold uppercase">
            Fight pimples with good bacteria
          </h1>
          <hr className="w-[400px] border-[#EAB1D5] border-[5px]" />
          <h2 className="text-black text-2xl font-bold uppercase">
            For an extraordinary microbiome.
          </h2>
        </div>
        <p className="text-black text-lg font-light pr-8">
          The microbiome-safe solution that effectively fights pimples with the power of
          good bacteria! Your skin brought back into balance naturally.
        </p>
      </div>
      <div className="flex-1">
        <img src="/assets/images/yun12.jpg" alt="" />
      </div>
    </div>
  );
};

export default Section1;
