import React from "react";

const Section5 = () => {
  return (
    <div className="relative h-[700px] mb-20">
      <div className="absolute bottom-0 left-0 w-[870px]">
        <img src="/assets/images/yun44.png" alt="" className="" />
        <div className="pt-20 w-[70%] absolute top-1/2 left-[47%] transform -translate-x-1/2 -translate-y-1/2 z-10">
          <h1 className=" text-center text-2xl font-bold text-black uppercase">
            There was a bacterium with superhero powers!
          </h1>
          <p className="text-center">
            That's YUNnie, a rod-shaped friendly lactobacillus that lives on your skin, as
            part of the skin microbiome. In fact,{" "}
            <strong>he takes care of you and your baby</strong> by safeguarding you every
            day, strengthening you against pathogens and restoring when there are wounds
            or sores. You can help him best in his tasks when you use microbiome-safe care
            and add extra of bacteria friends!{" "}
            <strong>
              This is precisely why the YUN PROBIOTIC BBY Ointment contains a lot of
              lactobacilli, because together they are stronger.
            </strong>
          </p>
        </div>
      </div>
      <div className="absolute top-20 right-0 w-[450px]">
        <img src="/assets/images/yun42.png" alt="bby" />
        <h1 className="w-[80%] text-center absolute top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-3xl font-bold text-[#8BD5ED] text-stroke-1 text-stroke-[#8BD5ED] uppercase">
          ONCE UPON A TIME...
        </h1>
      </div>
      <img src="/assets/images/yun48.png" alt="" className="absolute top-[30%] right-[30%] w-[150px]" />
      <img src="/assets/images/yun49.png" alt="" className="absolute bottom-[10%] left-[60%] w-[150px]" />
    </div>
  );
};

export default Section5;
