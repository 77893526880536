import React from "react";

const Section3 = () => {
  return (
    <div className="container mx-auto flex flex-col justify-center items-center gap-16">
      <h2 className="text-5xl text-black font-bold">DISCOVER OUR PRODUCTS</h2>
      <div className="grid grid-cols-4">
        <div className="flex flex-col items-center">
          <img src="../assets/images/yunPro1.jpg" alt="" className="w-[300px] aspect-square"/>
          <div className="relative bg-[#00ABAA] w-full aspect-[1.2]">
            <div className="flex flex-col gap-2 justify-center items-center h-[calc(100%-64px)]">
              <h2 className="text-white text-2xl font-black">ACN & ACN+</h2>
              <p className="text-white text-lg font-light">Acne symptoms</p>
            </div>
            <a
              href="/products/yun/acn"
              className="absolute bottom-0 right-0 px-8 py-5 bg-black text-white text-base font-black uppercase"
            >
              Discover more
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <img src="../assets/images/yunPro2.png" alt="" className="w-[300px] aspect-square"/>
          <div className="relative bg-[#ECB4BE] w-full aspect-[1.2]">
            <div className="flex flex-col gap-2 justify-center items-center h-[calc(100%-64px)]">
              <h2 className="text-white text-2xl font-black">VGN</h2>
              <p className="text-white text-lg font-light">Intimate care</p>
            </div>
            <a
              href="/products/yun/vgn"
              className="absolute bottom-0 right-0 px-8 py-5 bg-black text-white text-base font-black uppercase"
            >
              Discover more
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <img src="../assets/images/yunPro3.jpg" alt="" className="w-[300px] aspect-square"/>
          <div className="relative bg-[#95d4e9] w-full aspect-[1.2]">
            <div className="flex flex-col gap-2 justify-center items-center h-[calc(100%-64px)]">
              <h2 className="text-white text-2xl font-black">BBY</h2>
              <p className="text-white text-lg font-light">Baby skin care</p>
            </div>
            <a
              href="/products/yun/bby"
              className="absolute bottom-0 right-0 px-8 py-5 bg-black text-white text-base font-black uppercase"
            >
              Discover more
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <img src="../assets/images/yunPro4.png" alt="" className="w-[300px] aspect-square"/>
          <div className="relative bg-[#fdc899] w-full aspect-[1.2]">
            <div className="flex flex-col gap-2 justify-center items-center h-[calc(100%-64px)]">
              <h2 className="text-white text-2xl font-black">ATO</h2>
              <p className="text-white text-lg font-light">Atopic Eczema</p>
            </div>
            <a
              href="/products/yun/ato"
              className="absolute bottom-0 right-0 px-8 py-5 bg-black text-white text-base font-black uppercase"
            >
              Discover more
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
