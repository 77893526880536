import React from "react";
import Layout from "../../components/Layout";
import { FormattedMessage } from "react-intl";
import { useLanguage } from "../../contexts/LanguageContext";

const HomePage = () => {
  const { locale } = useLanguage(); 

  const fontClass = locale === "vi" ? "font-helvetica" : "font-sathu";

  return (
    <Layout>
      <div className={`w-full pb-[86px] flex flex-col justify-center items-center gap-3 bg-secondary ${fontClass}`}>
        <div className="relative flex justify-center bg-white items-center">
          <div className="absolute left-0 w-10 h-full bg-gradient-to-r from-secondary to-transparent"></div>
          <div className="absolute right-0 w-10 h-full bg-gradient-to-l from-secondary to-transparent"></div>
          <div className="absolute bottom-0 w-full h-20 bg-gradient-to-t from-secondary to-transparent"></div>
          <img
            src="../assets/images/video.gif"
            alt=""
            className="w-[800px] object-cover"
          />
        </div>

        <p
          className={`text-white mx-3 md:mx-10 font-medium text-2xl tracking-[0.4px] md:text-3xl lg:text-5xl leading-[30px] md:leading-[45px] lg:leading-[60px] text-center`}
        >
          <FormattedMessage id="home.ourMission" />
        </p>
        <div className="flex flex-col lg:flex-row gap-8 lg:gap-16 pt-6 lg:pt-20">
          <img
            className="w-full lg:w-1/2 order-first lg:order-last"
            src="../assets/images/gen.jpg"
            alt=""
          />
          <div className="w-full lg:w-1/2 flex flex-col gap-4">
            <h2 className="text-white text-3xl md:text-4xl lg:text-[42px] font-semibold">
              <FormattedMessage id="home.title" />
            </h2>
            <h3 className="text-white text-2xl md:text-3xl font-light">
              <FormattedMessage id="home.vision" />
            </h3>
            <p className="text-white text-base md:text-lg font-light">
              <FormattedMessage id="home.philosophy1" />
            </p>
            <p className="text-white text-base md:text-lg font-light">
              <FormattedMessage id="home.philosophy2" />
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
