import React from "react";

const Section6 = () => {
  return (
    <div className="container mx-auto flex flex-col items-center gap-14 py-14">
      <h2 className="text-center text-4xl text-black font-black">Proudly mentioned in</h2>
      <div className="flex gap-4 justify-center items-center w-full">
        <div>
          <img src="../../../assets/images/yun6.png" alt="" />
        </div>
        <div>
          <img src="../../../assets/images/yun7.png" alt="" />
        </div>
        <div>
          <img src="../../../assets/images/yun8.png" alt="" />
        </div>
        <div>
          <img src="../../../assets/images/yun9.png" alt="" />
        </div>
        <div>
          <img src="../../../assets/images/yun10.png" alt="" />
        </div>
        <div>
          <img src="../../../assets/images/yun11.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section6;
