import React from "react";

const Section2 = () => {
  return (
    <div className="mt-[100px] container mx-auto flex flex-col justify-center items-center gap-16 py-20">
      <h1 className="text-4xl font-bold text-black text-stroke-[2.5px] text-stroke-black uppercase">
        TRUST THEIR FIRST BATH WITH ALL OF YOUR HEART
      </h1>
      <div className="grid grid-cols-4 gap-14">
        <div className="flex flex-col items-center gap-5">
          <img src="/assets/images/yun29.png" alt="" className="w-[125px]" />
          <h2 className="text-black text-base font-light text-center">
            With live lactobacilli that strengthen the microbiome
          </h2>
        </div>
        <div className="flex flex-col items-center gap-5">
          <img src="/assets/images/yunMicrobiome.png" alt="" className="w-[125px]" />
          <h2 className="text-black text-base font-light text-center">
            Microbiome safe formulations
          </h2>
        </div>
        <div className="flex flex-col items-center gap-5">
          <img src="/assets/images/yunSafe.png" alt="" className="w-[125px]" />
          <h2 className="text-black text-base font-light text-center">
            Gentle & mild ingredients for sensitive and dry skin
          </h2>
        </div>
        <div className="flex flex-col items-center gap-5">
          <img src="/assets/images/yunFamily.png" alt="" className="w-[125px]" />
          <h2 className="text-black text-base font-light text-center">
            Also for mums and dads, brothers and sisters
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Section2;
