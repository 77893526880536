import React from "react";

const Section6 = () => {
  return (
    <div className="w-full relative">
      <div className="absolute w-1/2 h-full bg-[#FDC899] left-0" />
      <div className="flex container mx-auto relative">
        <div className="flex-1 bg-[#FDC899] py-20 pr-20 flex flex-col gap-8 justify-center">
          <h2 className="text-5xl font-bold italic text-stroke-2 text-stroke-black text-black">
            BREAK THE VICIOUS CYCLE
          </h2>
          <p className="text-black text-lg font-light">
            In severe cases of eczema, a nasty "itch-scratch" cycle can develop. The more
            you scratch, the more you damage the skin barrier, allowing bacteria and
            allergens to enter. This can lead to more inflammation and itching, which in
            turn leads to more scratching.
          </p>
          <p className="text-black text-lg font-light">
            Probiotics break the vicious cycle of atopic eczema by helping to repair,
            strengthen and protect the skin barrier. These good bacteria reduce
            inflammation, itching and redness, relieving the symptoms of eczema.
          </p>
        </div>
        <div className="flex-1">
          <img src="/assets/images/yun69.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section6;
