import React from "react";

const Section3 = () => {
  return (
    <div className="relative h-[700px]">
      <div className="absolute top-0 left-0 w-[600px]">
        <div className="relative flex flex-col justify-center items-center bg-cover bg-center bg-no-repeat aspect-video">
          <img
            src="/assets/images/yun42.png"
            alt="bby"
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          />
          <h1 className="pt-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-4xl font-bold text-[#8BD5ED] text-stroke-1 text-stroke-[#8BD5ED] uppercase">
            A match made
            <br /> in the womb
          </h1>
        </div>
      </div>
      <img src="/assets/images/yun43.png" alt="" className="absolute top-[160px] left-0 w-[500px]" />
      <div className="absolute top-[160px] right-0 w-[60%]">
        <div className="relative flex flex-col justify-center items-center bg-cover bg-center bg-no-repeat aspect-video">
          <img
            src="/assets/images/yun44.png"
            alt="bby"
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          />
          <div className="pt-20 w-[80%] absolute top-1/2 left-[47%] transform -translate-x-1/2 -translate-y-1/2 z-10">
            <h1 className=" text-center text-2xl font-bold text-black uppercase">
              IN mummy's tummy, your baby is not alone:{" "}
            </h1>
            <p className="text-center">
              They are surrounded by millions of microscopic bacteria. These tiny friends
              protect them during their first steps into the world and build a strong
              immune system.
            </p>
            <br />
            <p className="text-center">
              On top of that, mum gives them a strong layer of millions of good bacteria
              through the birth canal. YUN is purposefully microbiome-safe to take care of
              these millions of bacteria.{" "}
              <strong>
                Protecting their microbiome with YUN BBY protects them every day, always.
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
