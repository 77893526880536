import React from "react";

const Section8 = () => {
  return (
    <div className="relative ">
      <div className="absolute top-0 left-0 bg-[#ECB4BE] w-[70%] h-full z-[-1]"></div>
      <div className="flex gap-10 container mx-auto items-center">
        <div className="w-1/2 flex flex-col gap-8 py-20">
          <div className="flex flex-col">
            <h1 className="text-4xl italic text-black font-black pb-1 text-stroke-2 text-stroke-black">
              THE IMPORTANCE OF LACTOBACILLI <br />
              FOR A HEALTHY PH
            </h1>
            <hr className="w-[40%] border-white border-b-8" />
          </div>
          <p className="text-lg text-black font-light">
            The low pH of the vagina keeps problem-causing bacteria from growing. This is
            why you should use a microbiome-safe wash gel with a low pH down under. In
            fact, classic intimate soaps can disrupt the pH of your vagina, which does
            allow bad bacteria to develop.
          </p>
          <p className="text-lg text-black font-light">
            The lactobacilli in and around your vagina play a vital role: they produce
            lactic acid and keep the pH low and balanced -{" "}
            <strong className="font-bold">between pH 3.8 and 4.5</strong>. The active pH
            regulation of the YUN wash gels creates an ideal environment. By promoting the
            growth of lactobacilli on the one hand and inhibiting the growth of pathogens
            on the other, you reduce the risk of infection, itching and irritation.
          </p>
        </div>
        <div className="z-10">
          <img src="/assets/images/yun36.png" alt="" className="object-cover" />
        </div>
      </div>
    </div>
  );
};

export default Section8;
