import React from "react";

const Section6 = () => {
  return (
    <div className="flex justify-center items-center gap-10 container mx-auto">
      <div className="shadow-[-40px_40px_0_0_#00ABAA] flex-shrink-0">
        <img src="/assets/images/yun3.png" alt="" className="object-cover" />
      </div>
      <div className="flex flex-col gap-8">
        <h1 className="text-4xl italic text-black font-black pb-1 border-b-8 border-[#00ABAA] w-fit">
          90% BACTERIA,
          <br />
          10% HUMAN
        </h1>
        <p className="text-lg text-black font-light">
          Our skin is covered with billions of microscopic organisms such as bacteria,
          viruses, fungi. A tropical rainforest, just on our skin. These microscopic
          friends take good care of our health, day and night. But do we take good care of
          them?
        </p>
        <p className="text-lg text-black font-light">
          By using daily skin care products with antibacterial ingredients, we lose many
          of these friends and become more susceptible to all kinds of bad bacteria. Acne
          bacteria also spring into action, making it easier for them to cause pimples and
          acne symptoms.
        </p>
        <p className="text-lg text-black font-light">
          ACN breaks this vicious circle by adding millions of good bacteria that
          rebalance your microbiome.
        </p>
      </div>
    </div>
  );
};

export default Section6;
