import React from "react";
import { FormattedMessage } from "react-intl";

const Section9 = () => {
  return (
    <div className="pt-10 container mx-auto grid grid-cols-1 xl:grid-cols-2 gap-12 justify-center items-center p-6 xl:p-0">
      <div className="flex flex-col gap-6">
        <h2 className="text-2xl xl:text-4xl uppercase font-medium text-gray-700">
          <FormattedMessage id="bio.section9.title" />
        </h2>
        <hr className="w-[15%] border-t border-4 border-[#3088B3]" />

        <ul className="list-disc ml-3 text-xl flex flex-col gap-4 uppercase text-gray-500">
          <li>
            <FormattedMessage id="bio.section9.description1" />
          </li>
          <li>
            <FormattedMessage id="bio.section9.description2" />
          </li>
          <li>
            <FormattedMessage id="bio.section9.description3" />
          </li>
          <li>
            <FormattedMessage id="bio.section9.description4" />
          </li>
          <li>
            <FormattedMessage id="bio.section9.description5" />
          </li>
          <li>
            <FormattedMessage id="bio.section9.description6" />
          </li>
          <li>
            <FormattedMessage id="bio.section9.description7" />
          </li>
        </ul>
      </div>
      <div className="grid grid-cols-3 gap-3">
        <img
          src="https://www.biodermogenesi.com/uk/wp-content/uploads/sites/4/2022/10/bdg-aa-004-pre-e-post.jpg"
          alt=""
        />
        <img
          src="https://www.biodermogenesi.com/uk/wp-content/uploads/sites/4/2022/10/bdg-aa-011-pre-e-post.jpg"
          alt=""
        />
        <img
          src="https://www.biodermogenesi.com/uk/wp-content/uploads/sites/4/2022/10/bdg-aa-016-pre-e-post.jpg"
          alt="Biodermogenesi"
        />
        <img
          src="https://www.biodermogenesi.com/uk/wp-content/uploads/sites/4/2022/10/bdg-aa-001-pre-e-post.jpg"
          alt=""
        />
        <img
          src="https://www.biodermogenesi.com/uk/wp-content/uploads/sites/4/2022/10/bdg-aa-005-pre-e-post.jpg"
          alt=""
        />
        <img
          src="https://www.biodermogenesi.com/uk/wp-content/uploads/sites/4/2022/10/bdg-aa-007-pre-e-post.jpg"
          alt=""
        />
      </div>
    </div>
  );
};

export default Section9;
