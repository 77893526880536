import React from "react";
import { FormattedMessage } from "react-intl";

const Section14 = () => {
  return (
    <div className="p-6 xl:p-0 container mx-auto flex flex-col gap-12 justify-center items-center">
      <div className="flex flex-col gap-4 justify-center items-center">
        <h2 className="text-3xl xl:text-4xl text-center xl:text-left uppercase font-medium text-gray-700">
          <FormattedMessage id="bio.section14.title" />
        </h2>
        <hr className="w-[15%] border-t border-4 border-[#3088B3]" />
      </div>
      <div className="w-full xl:w-[85%] aspect-video">
        <video src="../assets/videos/bio.mp4" controls></video>
      </div>
    </div>
  );
};

export default Section14;
