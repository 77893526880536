import React from "react";
import { FormattedMessage } from "react-intl";

const Section15 = () => {
  return (
    <div className="relative bg-[radial-gradient(circle_at_right,rgba(214,239,232,0.15)_32%,#D6EFE8_100%)] p-6 xl:p-0">
      <div className="w-full container mx-auto relative flex flex-col xl:flex-row justify-center items-center gap-16 overflow-hidden">
        <div className="flex flex-col gap-8">
          <h1 className="text-3xl xl:text-[56px] leading-[1.2em] uppercase font-semibold text-[#575756] xl:w-[560px]">
            <FormattedMessage id="bio.section15.title" />
          </h1>
          <h2 className="text-base xl:text-[24px] leading-[1.2em] uppercase font-light text-[#575756] xl:w-[560px]">
            <FormattedMessage id="bio.section15.description" />
          </h2>
        </div>
        <div className="">
          <img
            src="../assets/images/bio6.png"
            alt="Biodermogenesi"
            className="w-full xl:w-[580px] h-full object-cover "
          />
        </div>
      </div>
      <img
        src="../assets/images/bio7.png"
        alt="Biodermogenesi"
        className="z-[-1] absolute bottom-0 right-0 w-full xl:w-[892px] xl:h-full xl:object-cover "
      />
    </div>
  );
};

export default Section15;
