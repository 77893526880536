import React from "react";
import { FormattedMessage } from "react-intl";

const Section10 = () => {
  return (
    <div className="p-6 xl:p-0 h-full bg-[radial-gradient(circle_at_right,rgba(214,239,232,0.15)_32%,#D6EFE8_100%)]">
      <div className="h-full w-full container mx-auto relative flex justify-center items-center gap-16 overflow-hidden">
        <div className="h-full justify-end items-end hidden xl:flex">
          <img
            src="../assets/images/bio4.png"
            alt="Biodermogenesi"
            className="w-full h-[558px] object-cover flex-shrink-0"
          />
        </div>
        <div className="flex flex-col gap-8 my-8">
          <img
            src="../assets/logos/logoBio.png"
            alt="Biodermogenesi"
            className="w-[190px] hidden xl:block"
          />
          <h1 className="text-3xl xl:text-[56px] leading-[1.2em] uppercase font-semibold text-[#575756] xl:w-[560px]">
            <FormattedMessage id="bio.section10.title" />
          </h1>
          <h2 className="text-base xl:text-[24px] leading-[1.2em] uppercase font-light text-[#575756] xl:w-[560px]">
            <FormattedMessage id="bio.section10.description" />
          </h2>
          <img
            src="../assets/images/bio4.png"
            alt="Biodermogenesi"
            className="w-full h-full object-cover flex-shrink-0 xl:hidden"
          />
          <div className="flex justify-center items-center xl:hidden">
            <img
              src="../assets/logos/logoBio.png"
            alt="Biodermogenesi"
              className="w-[190px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section10;
