import React from 'react'

const Section4 = () => {
  return (
    <div className='container mx-auto grid grid-cols-3 gap-10'>
      <img src="/assets/images/yun61.png" alt="" />
      <img src="/assets/images/yun62.png" alt="" />
      <img src="/assets/images/yun63.png" alt="" />
    </div>
  )
}

export default Section4
