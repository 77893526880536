import React from "react";

const Section5 = () => {
  return (
    <div className="mt-20 container mx-auto flex flex-col justify-center items-center gap-8 xl:gap-16 w-full">
      <h1 className="text-center text-2xl xl:text-5xl font-bold">
        REAL PEOPLE. REAL RESULTS
      </h1>
      <p>
        The result of combining your consistent daily routine with our effective formulas?
        That’s bananas!
      </p>
      <div className="grid grid-cols-2 xl:grid-cols-3 gap-6">
        <img src="/assets/images/yun18.jpg" alt="acneSymptoms1" />
        <img src="/assets/images/yun19.jpg" alt="acneSymptoms1" />
        <img src="/assets/images/yun20.jpg" alt="acneSymptoms1" />
      </div>
    </div>
  );
};

export default Section5;
