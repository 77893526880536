import React from "react";

const Section9 = () => {
  return (
    <div className="bg-[#FEEFE1] py-20">
      <div className="container mx-auto flex flex-col gap-32 justify-center items-center">
        <h1 className="text-5xl font-bold italic text-stroke-2 text-stroke-black text-black">
          THE BENEFITS OF PRE-, PRO- AND POSTBIOTICS
        </h1>
        <div className="grid grid-cols-3 gap-10">
          <div className="relative flex flex-col justify-center items-center gap-8 bg-[#FCE2CC] pt-24 pb-10">
            <div className="absolute top-0 -translate-y-1/2 left-1/2 -translate-x-1/2 w-[148px] h-[148px] rounded-full bg-[#FCC99C] text-white flex justify-center items-center text-4xl uppercase font-bold">
              Pre
            </div>
            <h2 className="text-2xl font-bold">PREBIOTICS</h2>
            <div className="flex flex-col justify-center items-center px-10">
              <p className="text-xl text-center text-[#FFB36F] font-bold">
                Feed the good bacteria
              </p>
              <p className="text-lg text-center font-light">
                Prebiotics are nutrients for the good bacteria that naturally live on our
                skin. At YUN, we use prebiotics such as inulin and fructose to stimulate
                the growth of good bacteria and improve the balance of the skin
                microbiome.
              </p>
            </div>
          </div>
          <div className="relative flex flex-col justify-center items-center gap-8 bg-[#FCE2CC] pt-24 pb-10">
            <div className="absolute top-0 -translate-y-1/2 left-1/2 -translate-x-1/2 w-[148px] h-[148px] rounded-full bg-[#FCC99C] text-white flex justify-center items-center text-4xl uppercase font-bold">
              Pro
            </div>
            <h2 className="text-2xl font-bold">PROBIOTICS</h2>
            <div className="flex flex-col justify-center items-center px-10">
              <p className="text-xl text-center text-[#FFB36F] font-bold">
                Restore the microbiome
              </p>
              <p className="text-lg text-center font-light">
                Probiotics are live microorganisms, such as lactobacilli. YUN uses
                specific strains of bacteria that help to restore and strengthen the skin
                microbiome and inhibit the growth of bad bacteria.
              </p>
            </div>
          </div>
          <div className="relative flex flex-col justify-center items-center gap-8 bg-[#FCE2CC] pt-24 pb-10">
            <div className="absolute top-0 -translate-y-1/2 left-1/2 -translate-x-1/2 w-[148px] h-[148px] rounded-full bg-[#FCC99C] text-white flex justify-center items-center text-4xl uppercase font-bold">
              Post
            </div>
            <h2 className="text-2xl font-bold">POSTBIOTICS</h2>
            <div className="flex flex-col justify-center items-center px-10">
              <p className="text-xl text-center text-[#FFB36F] font-bold">
                Strengthen the microbiome
              </p>
              <p className="text-lg text-center font-light">
                Postbiotics are beneficial substances produced by probiotics. They help
                reduce inflammation and contribute to hydrating and repairing the skin.
                These substances support your body, for example, by strengthening your
                immune system.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section9;
