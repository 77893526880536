import React from "react";
import { FormattedMessage } from "react-intl";

const Section8 = () => {
  return (
    <div className="relative">
      <div className=" p-6 xl:p-0 bg-[radial-gradient(circle_at_right,rgba(214,239,232,0.15)_32%,#D6EFE8_100%)]">
        <div className="w-full container mx-auto relative flex flex-col xl:flex-row justify-center items-center gap-16 overflow-hidden">
          <div className="flex flex-col gap-8">
            <h1 className="text-4xl xl:text-[56px] leading-[1.2em] uppercase font-semibold text-[#575756] xl:w-[560px]">
              <FormattedMessage id="bio.section8.title" />
            </h1>
            <h2 className="text-[24px] leading-[1.2em] uppercase font-light text-[#575756] xl:w-[560px]">
              <FormattedMessage id="bio.section8.description1" />
            </h2>
          </div>
          <div className="">
            <img
              src="../assets/images/bio3.png"
              alt="Biodermogenesi"
              className="w-[580px] h-full object-cover "
            />
          </div>
        </div>
      </div>
      <div className="w-full h-[100px] container mx-auto">
        <p className="px-4 xl:px-0 pt-32 xl:pt-0 text-[11px] leading-[1.2em] py-2">
          <FormattedMessage id="bio.section8.description2" />
        </p>
        <div className="absolute bottom-[-20px] left-1/2 transform -translate-x-1/2  xl:left-[66%] w-[197px] h-[197px] bg-[#0c71c3] rounded-full flex justify-center items-center">
          <div className="flex flex-col gap-1">
            <h2 className="text-[35px] leading-[1.2em] uppercase font-medium text-white text-center">
              + 35,5 %<br />
            </h2>
            <span className="text-[20px] text-white">
              <FormattedMessage id="bio.section8.description3" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section8;
