import React from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";
import HomeSection6 from "../Home/Section6";

const Bby = () => {
  return (
    <div className="w-full">
      <Header />
      <div className="flex flex-col w-full">
        <Section1 />
        <div className="z-10 bg-gradient-to-b from-[#EDF8FE] to-[#B4E2F5]">
          <div className="container mx-auto">
            <Section2 />
            <Section3 />
            <Section4 />
            <Section5 />
          </div>
        </div>
        <Section6 />
        <Section7 />
        <HomeSection6 />
      </div>
      <Footer />
    </div>
  );
};

export default Bby;
