import React from 'react'

const Section7 = () => {
  return (
    <div className="container mx-auto">
      <img src="/assets/images/yun70.jpg" alt="" />
    </div>
  );
}

export default Section7
