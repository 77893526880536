import React from "react";

const Section9 = () => {
  return (
    <div className="flex justify-center items-center gap-20 container mx-auto">
      <div className="shadow-[-30px_30px_0_0_#EFB6C0] flex-shrink-0">
        <img src="/assets/images/yun38.png" alt="" className="object-cover" />
      </div>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col">
          <h1 className="text-4xl italic text-black text-stroke-2 text-stroke-black font-black pb-1 w-fit">
            HOW DO YOU PREVENT VAGINAL INFECTIONS?{" "}
          </h1>
          <hr className="w-[40%] border-[#EFB6C0] border-b-8" />
        </div>
        <p className="text-lg text-black font-light">
          By following a few simple rules and changing your hygiene habits, you can easily
          avoid infections and irritations. Remember it's important to let your female
          parts breathe and maintain a healthy pH level! Check out our 10 best tips for
          daily intimate care.
        </p>
      </div>
    </div>
  );
};

export default Section9;
