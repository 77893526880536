import React from "react";

const Section4 = () => {
  return (
    <div className="container mx-auto flex flex-col gap-8 xl:gap-16 w-full">
      <h1 className="text-center text-2xl xl:text-5xl font-bold">
        THE MIGHTY ABILITY OF THE ACN THERAPY
      </h1>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-10">
        <div className="flex flex-col gap-4 items-center">
          <img src="/assets/images/yun13.png" alt="acneSymptoms1" />
          <div className="flex flex-col items-center">
            <h3 className="text-3xl font-bold italic">OUR INNOVATION</h3>
            <h2 className="text-5xl font-bold italic text-[#00ACAB] text-stroke-2 text-stroke-[#00ACAB]">
              MICROCAPSULES
            </h2>
          </div>
          <p className="text-center text-xl font-light tracking-tighter">
            To keep bacteria alive for a long time, we developed a brand new
            microencapsulation technique. These little "pearls", about 1mm in diameter,
            serve as temporary sleeping bags for the good living bacteria. Safely stored
            in these microcapsules, they are ready to do their duty as soon as they come
            into contact with your skin.
          </p>
        </div>
        <div className="flex flex-col gap-4 items-center">
          <img src="/assets/images/yun14.png" alt="acneSymptoms1" />
          <div className="flex flex-col items-center">
            <h3 className="text-3xl font-bold italic">100% VISIBLE IMPROVEMENT</h3>
            <h2 className="text-5xl font-bold italic text-[#00ACAB] text-stroke-2 text-stroke-[#00ACAB]">
              IN 4 WEEKS
            </h2>
          </div>
          <p className="text-center text-xl font-light tracking-tighter">
            ACN works well, unbelievably well in fact! In our double-blind clinical study,
            the scientific evidence proved that bacteria effectively eliminate pimples. Important to know: 4 weeks are absolutely
            necessary to restore your microbiome back to a healthy balance. That is why it
            is also super normal to have a little more pimples in the first week. Don't
            worry, you can expect 100% improvement!
          </p>
        </div>
      </div>
      <div className="grid grid-cols-2 xl:grid-cols-3 gap-6">
        <img src="/assets/images/yun15.png" alt="acneSymptoms1" />
        <img src="/assets/images/yun16.png" alt="acneSymptoms1" />
        <img src="/assets/images/yun17.png" alt="acneSymptoms1" />
      </div>
    </div>
  );
};

export default Section4;
