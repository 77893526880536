import React from 'react'

const Section4 = () => {
  return (
    <div className='grid grid-cols-3 gap-6'>
      <img src="/assets/images/yun45.png" alt=""  />
      <img src="/assets/images/yun46.png" alt="" />
      <img src="/assets/images/yun47.png" alt="" />
    </div>
  )
}

export default Section4
