import React from "react";

const Section2 = () => {
  return (
    <div className="container mx-auto flex flex-col justify-center items-center gap-16 py-20">
      <div className="grid grid-cols-4 gap-4">
        <div className="flex flex-col items-center gap-5">
          <img src="/assets/images/yun29.png" alt="" className="w-[100px]" />
          <h2 className="text-black text-lg font-bold text-center uppercase text-stroke-[0.7px] text-stroke-black">
            UNIQUE TECHNOLOGY CONTAINING LIVE LACTOBACILLI
          </h2>
        </div>
        <div className="flex flex-col items-center gap-5">
          <img src="/assets/images/yun30.png" alt="" className="w-[100px]" />
          <h2 className="text-black text-lg font-bold text-center uppercase text-stroke-[0.7px] text-stroke-black">
            Active pH regulation via lactate
          </h2>
        </div>
        <div className="flex flex-col items-center gap-5">
          <img src="/assets/images/yunSafe.png" alt="" className="w-[100px]" />
          <h2 className="text-black text-lg font-bold text-center uppercase text-stroke-[0.7px] text-stroke-black">
            Safe during pregnancy and breastfeeding
          </h2>
        </div>
        <div className="flex flex-col items-center gap-5">
          <img src="/assets/images/yunMicrobiome.png" alt="" className="w-[100px]" />
          <h2 className="text-black text-lg font-bold text-center uppercase text-stroke-[0.7px] text-stroke-black">
            100% <br /> MICROBIOME SAFE
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Section2;
