import React, { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FormattedMessage } from "react-intl";

const Section2 = () => {
  const [visibleItems, setVisibleItems] = useState({
    item1: false,
    item2: false,
    item3: false,
    item4: false,
  });

  const itemRefs = {
    item1: useRef(null),
    item2: useRef(null),
    item3: useRef(null),
    item4: useRef(null),
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const itemId = entry.target.dataset.item;
          if (entry.isIntersecting) {
            setVisibleItems((prev) => ({
              ...prev,
              [itemId]: true,
            }));
          }
        });
      },
      { threshold: 0.5 }
    );

    Object.values(itemRefs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="p-6 xl:p-0 container mx-auto flex flex-col gap-12">
      <div className="flex flex-col gap-4">
        <h2 className="text-2xl xl:text-[32px] leading-[1.2em] uppercase font-medium text-gray-700">
          <FormattedMessage id="bio.section2.title" />
        </h2>
        <hr className="w-1/3 xl:w-[10%] border-t border-4 border-[#3088B3]" />
      </div>

      <div className="grid grid-cols-1 xl:grid-cols-4 gap-10">
        <div
          ref={itemRefs.item1}
          data-item="item1"
          className="w-2/3 xl:w-full flex flex-col gap-6 mx-auto"
        >
          <div className="relative aspect-square">
            <CircularProgressbar
              value={visibleItems.item1 ? 100 : 0}
              strokeWidth={2}
              styles={{
                path: {
                  stroke: "#3088B3",
                  transition: "stroke-dashoffset 2.5s ease-in-out",
                },
                trail: {
                  stroke: "#d6d6d6",
                },
              }}
            />
            <div className="absolute inset-0 flex justify-center items-center text-6xl font-medium text-gray-600">
              {visibleItems.item1 ? <CountUp end={1016} duration={2.5} /> : "0"}
            </div>
          </div>
          <p className="text-base text-center">
            <FormattedMessage id="bio.section2.description1" />
          </p>
        </div>

        <div
          ref={itemRefs.item2}
          data-item="item2"
          className="w-2/3 xl:w-full flex flex-col gap-6 mx-auto"
        >
          <div className="relative aspect-square">
            <CircularProgressbar
              value={visibleItems.item2 ? 99 : 0}
              strokeWidth={2}
              styles={{
                path: {
                  stroke: "#3088B3",
                  transition: "stroke-dashoffset 2.5s ease-in-out",
                },
                trail: {
                  stroke: "#d6d6d6",
                },
              }}
            />
            <div className="absolute inset-0 flex justify-center items-center text-6xl font-medium text-gray-600">
              {visibleItems.item2 ? <CountUp end={99} duration={2.5} /> : "0"}
            </div>
          </div>
          <p className="text-base text-center">
            <FormattedMessage id="bio.section2.description2" />
          </p>
        </div>

        <div
          ref={itemRefs.item3}
          data-item="item3"
          className="w-2/3 xl:w-full flex flex-col gap-6 mx-auto"
        >
          <div className="relative aspect-square">
            <CircularProgressbar
              value={visibleItems.item3 ? 83 : 0}
              strokeWidth={2}
              styles={{
                path: {
                  stroke: "#3088B3",
                  transition: "stroke-dashoffset 2.5s ease-in-out",
                },
                trail: {
                  stroke: "#d6d6d6",
                },
              }}
            />
            <div className="absolute inset-0 flex justify-center items-center text-6xl font-medium text-gray-600">
              {visibleItems.item3 ? (
                <>
                  <CountUp end={83} duration={2.5} />
                  <span>%</span>
                </>
              ) : (
                "0"
              )}{" "}
            </div>
          </div>
          <p className="text-base text-center">
            <FormattedMessage id="bio.section2.description3" />
          </p>
        </div>

        <div
          ref={itemRefs.item4}
          data-item="item4"
          className="w-2/3 xl:w-full flex flex-col gap-6 mx-auto"
        >
          <div className="relative aspect-square">
            <CircularProgressbar
              value={visibleItems.item4 ? 100 : 0}
              strokeWidth={2}
              styles={{
                path: {
                  stroke: "#3088B3",
                  transition: "stroke-dashoffset 2.5s ease-in-out",
                },
                trail: {
                  stroke: "#d6d6d6",
                },
              }}
            />
            <div className="absolute inset-0 flex justify-center items-center text-6xl font-medium text-gray-600">
              {visibleItems.item3 ? (
                <>
                  <CountUp end={100} duration={2.5} />
                  <span>%</span>
                </>
              ) : (
                "0"
              )}{" "}
            </div>
          </div>
          <p className="text-base text-center">
            <FormattedMessage id="bio.section2.description4" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section2;
