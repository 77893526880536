import React from "react";

const Section7 = () => {
  return (
    <div className="py-20 container mx-auto flex flex-col gap-8 xl:gap-16 w-full justify-center items-center border-b border-black">
      <h1 className="text-5xl font-bold text-stroke-2 text-stroke-black text-black">
        VGN PRODUCTS
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
        <img src="/assets/images/yun33.png" alt="" />
        <img src="/assets/images/yun34.png" alt="" />
        <img src="/assets/images/yun35.png" alt="" />
      </div>
      <div className="flex flex-row gap-2">
        <span className="w-2 rounded-full h-2 bg-[#eab696]"></span>
        <span className="w-2 rounded-full h-2 bg-[#eab696] opacity-50"></span>
        <span className="w-2 rounded-full h-2 bg-[#eab696] opacity-50"></span>
      </div>
    </div>
  );
};

export default Section7;
