import React from "react";

const Section5 = () => {
  return (
    <div className="bg-[#F6E0E4] ">
      <div className="flex flex-col items-center gap-14 w-3/5 mx-auto relative pt-28 py-14">
        <img
          src="../../../assets/images/yun5.png"
          alt=""
          className="absolute -top-12 left-1/2 -translate-x-1/2"
        />
        <h2 className="text-center text-4xl text-black font-black">
          "Unfortunately, YUN didn't exist when I was younger! Really worth the money."
        </h2>
        <h3 className="text-center text-2xl text-black font-light">Ilvy</h3>
        <div className="flex gap-4">
          <span className="w-2 h-2 bg-gray-400 rounded-full"></span>
          <span className="w-2 h-2 bg-gray-400 rounded-full"></span>
          <span className="w-2 h-2 bg-black rounded-full"></span>
        </div>
      </div>
    </div>
  );
};

export default Section5;
