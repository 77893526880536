import React from "react";
import Layout from "../../components/Layout";
import { FormattedMessage } from "react-intl";
import { useLanguage } from "../../contexts/LanguageContext";

const Products = () => {
  const { locale } = useLanguage();
  const fontClass = locale === "vi" ? "font-helvetica" : "font-sathu";

  return (
    <Layout>
      <div
        className={`flex flex-col gap-14 pb-10 md:p-0 md:py-[40px] text-white ${fontClass}`}
      >
        <div className="flex flex-col gap-7">
          <h2 className="uppercase text-2xl">
            <FormattedMessage id="product.title" />
          </h2>
          <p className="text-base">
            <FormattedMessage id="product.description" />
          </p>
        </div>
        <div className="flex flex-col gap-16 xl:gap-32 w-full justify-center items-center">
          <div className="flex gap-10 w-full xl:flex-row flex-col-reverse">
            <div className="flex flex-col justify-center gap-5 xl:w-3/5">
              <h2 className="text-2xl font-bold uppercase">Biodermogenesi</h2>
              <p className="text-base">
                <FormattedMessage id="bio.section2.title" />
              </p>
              <a
                href="/products/biodermogenesi"
                className="bg-white text-primary px-5 py-2 rounded-md w-fit"
              >
                <FormattedMessage id="readMore" />
              </a>
            </div>

            <div className="relative xl:w-2/5">
              <div className="absolute bottom-[-20px] left-[20px] h-full aspect-square bg-orange-200 z-0"></div>
              <div className="relative bg-gradient-to-r from-[#EFE7D6] to-white w-[95%] aspect-square">
                <img
                  src="../assets/images/bio1.png"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>

          <div className="flex gap-10 w-full flex-col xl:flex-row">
            <div className="xl:w-2/5">
              <div className="relative bg-gradient-to-r from-[#EFE7D6] to-white w-[95%] aspect-square">
                <img
                  src="../assets/images/yun1.jpg"
                  alt=""
                  className="w-full h-full object-cover shadow-[20px_20px_0_0_#fed7aa]"
                />
              </div>
            </div>
            <div className="flex flex-col justify-center gap-5 xl:w-3/5">
              <h2 className="text-2xl font-bold uppercase">Yun</h2>
              <p className="text-base">
                <FormattedMessage id="yun.description" />
              </p>
              <a
                href=""
                className="bg-white text-primary px-5 py-2 rounded-md w-fit"
              >
                <FormattedMessage id="comingSoon" />
              </a>
            </div>
          </div>

          <div className="flex gap-10 w-full xl:flex-row flex-col-reverse">
            <div className="flex flex-col justify-center gap-5 xl:w-3/5">
              <h2 className="text-2xl font-bold uppercase">
                <FormattedMessage id="product.comingSoon" />
              </h2>
              <p className="text-base">
                <FormattedMessage id="product.title.1" />
              </p>
              <button className="bg-white text-primary px-5 py-2 rounded-md w-fit">
                <FormattedMessage id="comingSoon" />
              </button>
            </div>
            <div className="xl:w-2/5 bg-gradient-to-r from-[#EFE7D6] to-white w-[95%] aspect-square">
              <img
                src="../assets/images/coming1.jpg"
                alt=""
                className="w-full h-full object-cover shadow-[20px_20px_0_0_#3b82f6]"
              />
            </div>
          </div>

          <div className="flex gap-10 w-full flex-col xl:flex-row">
            <div className="xl:w-2/5 bg-gradient-to-r from-[#EFE7D6] to-white w-[95%] aspect-square">
              <img
                src="../assets/images/coming1.jpg"
                alt=""
                className="w-full h-full object-cover shadow-[20px_20px_0_0_#3b82f6]"
              />
            </div>
            <div className="flex flex-col justify-center gap-5 xl:w-3/5">
              <h2 className="text-2xl font-bold uppercase">
                <FormattedMessage id="product.comingSoon" />
              </h2>
              <p className="text-base">
                <FormattedMessage id="product.title.2" />
              </p>
              <button className="bg-white text-primary px-5 py-2 rounded-md w-fit">
                <FormattedMessage id="comingSoon" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Products;
