import React from "react";
import { FormattedMessage } from "react-intl";

const Section4 = () => {
  return (
    <div className="p-6 xl:p-0 container mx-auto flex flex-col-reverse xl:flex-row gap-8 xl:gap-12 justify-center items-center">
      <div className="w-full xl:w-1/2 grid grid-cols-3 gap-3">
        <img
          src="https://www.biodermogenesi.com/uk/wp-content/uploads/sites/4/2022/10/bdg-smto-005-pre-e-post_VERTICALE.jpg"
          alt=""
        />
        <img
          src="https://www.biodermogenesi.com/uk/wp-content/uploads/sites/4/2022/10/bdg-sm-015-pre-e-post.jpg"
          alt=""
        />
        <img
          src="https://www.biodermogenesi.com/uk/wp-content/uploads/sites/4/2022/10/bdg-sm-030-pre-e-post.jpg"
          alt="Biodermogenesi"
        />
      </div>
      <div className="w-full xl:w-1/2 flex flex-col gap-6">
        <h2 className="text-4xl uppercase font-medium text-[#3088b3]">
          <FormattedMessage id="bio.section4.title" />
        </h2>
        <hr className="w-[15%] border-t border-4 border-[#3088B3]" />

        <ul className="list-disc ml-3 text-base flex flex-col gap-4 uppercase">
          <li>
            <FormattedMessage id="bio.section4.description1" />
          </li>
          <li>
            <FormattedMessage id="bio.section4.description2" />
          </li>
          <li>
            <FormattedMessage id="bio.section4.description3" />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Section4;
