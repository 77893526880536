import React from "react";
import { FormattedMessage } from "react-intl";

const Section12 = () => {
  return (
    <div className="bg-[#FAF0E2] xl:h-[512px]">
      <div className="w-full h-full container mx-auto relative flex flex-col xl:flex-row justify-center items-center gap-16 overflow-hidden p-6 xl:p-0">
        <div className="flex flex-col gap-8">
          <h1 className="text-3xl xl:text-[56px] leading-[1.2em] uppercase font-semibold text-[#575756] xl:w-[560px]">
            <FormattedMessage id="bio.section12.title" />
          </h1>
          <h2 className="text-base xl:text-[24px] leading-[1.2em] uppercase font-light text-[#575756] xl:w-[560px]">
            <FormattedMessage id="bio.section12.description" />
          </h2>
          <div className=" xl:hidden">
            <img
              src="../assets/images/bio5.png"
              alt="Biodermogenesi"
              className="w-full h-full object-cover flex-shrink-0"
            />
          </div>
          <button className="bg-[#3088B3] uppercase mx-auto xl:mx-0 w-fit text-white px-4 py-2 rounded-md">
            <FormattedMessage id="bio.section12.button" />
          </button>
        </div>
        <div className="hidden xl:block">
          <img
            src="../assets/images/bio5.png"
            alt="Biodermogenesi"
            className="w-full h-[512px] object-cover flex-shrink-0"
          />
        </div>
      </div>
    </div>
  );
};

export default Section12;
