import React from "react";

const Section1 = () => {
  return (
    <div className="w-full flex">
      <div className="w-1/2 bg-[#EFB6C0] flex flex-col gap-16 justify-center pl-[125px]">
        <div className="flex flex-col gap-8">
          <h1 className="text-white text-5xl font-bold uppercase text-stroke-2 text-stroke-white">
            CREATE A HEALTHY
            <br /> HOME
            <br /> FOR YOUR INTIMATE
            <br /> MICROBIOME
          </h1>
          <h2 className="text-black text-4xl font-bold uppercase">
            BECAUSE A CLEAN VAGINA<br /> HAS BACTERIA
          </h2>
        </div>
        <p className="text-black text-lg font-light pr-8">
          VGN helps prevent vaginal infections, strengthens the vaginal balance with 100%
          microbiome-safe ingredients and actively restores with live bacteria.
        </p>
      </div>
      <div className="w-1/2">
        <img src="/assets/images/yunPro2.png" alt="" />
      </div>
    </div>
  );
};

export default Section1;
