import React from "react";
import Footer from "../Footer";
import Header from "../Header";

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen bg-secondary">
      <Header />
      <main className="flex-grow pt-4 px-4 sm:px-6 md:px-[40px] lg:px-[120px] bg-secondary">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
