import React from "react";
import { FormattedMessage } from "react-intl";

const Section1 = () => {
  return (
    <div className="pt-[92px] bg-gradient-to-r from-[#EFE7D6] to-transparent">
      <div className="h-full w-full xl:py-28 p-6 container mx-auto relative flex flex-col justify-center items-center xl:items-start gap-8 xl:gap-16">
        <h1 className="text-4xl xl:text-[56px] leading-[1.2em] text-center xl:text-left uppercase font-semibold text-[#575756] xl:w-[560px]">
          <FormattedMessage id="bio.section1.title" />
        </h1>
        <h2 className="text-lg xl:text-[24px] leading-[1.2em] text-center xl:text-left uppercase font-light text-[#575756] xl:w-[560px]">
          <FormattedMessage id="bio.section1.description" />{" "}
          <strong className="font-bold">
            <FormattedMessage id="bio.section1.description.strong" />
          </strong>
        </h2>
        <div className="xl:absolute right-0">
          <img
            src="../assets/images/bio1.png"
            alt="Biodermogenesi"
            className="w-full h-full"
          />
        </div>
        <button className="bg-[#3088b3] text-white px-8 py-2 rounded-sm w-fit">
          <FormattedMessage id="bio.section1.button" />
        </button>
      </div>
    </div>
  );
};

export default Section1;
