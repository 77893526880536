import React from "react";
import { FormattedMessage } from "react-intl";

const Section6 = () => {
  return (
    <div className="p-6 xl:p-0 container mx-auto flex flex-col gap-8 xl:gap-12 justify-center items-center">
      <div className="flex flex-col gap-4 justify-center items-center">
        <h2 className="text-2xl xl:text-4xl text-center uppercase font-medium text-gray-700">
          <FormattedMessage id="bio.section6.title" />
        </h2>
        <hr className="w-[15%] border-t border-4 border-[#3088B3]" />
      </div>
      <div className="w-full xl:w-[85%] aspect-video">
        <iframe
          title="Biodermogenesi"
          src="https://player.vimeo.com/video/707822548?dnt=1&app_id=122963"
          width="100%"
          height="100%"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Section6;
