import React from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import Section1 from "./Home/Section1";
import Section2 from "./Home/Section2";
import Section3 from "./Home/Section3";
import Section4 from "./Home/Section4";
import Section5 from "./Home/Section5";
import Section6 from "./Home/Section6";

const Yun = () => {
  return (
    <div className="w-full">
      <Header />
      <div className="flex flex-col gap-8 xl:gap-16 w-full">
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
      </div>
      <Footer />
    </div>
  );
};

export default Yun;
